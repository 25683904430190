.main-header{
  .container{
    @include mediaMax(950px){
      width: 100%;
      padding-left: 0;
      padding-right: 0;
    }
  }
  .header-top{
    padding-top: 18px;
    padding-bottom: 10px;
    @include clear();
    .header-top-logo{
      float: left;
      padding-left: 25px;
      img{
        display: block;
      }
    }
    .header-top-phone{
      float: right;
      margin-right: 140px;
      a{
        font-style: italic;
        font-weight: 600;
        font-size: 31px;
        color: #c9ab6d;
        border-bottom: none;
        img{
          display: block;
        }
      }
      @include mediaMax(1000px){
        text-align: center;
        width: 32%;
        margin-right: 0;
      }
    }
    @include mediaMax(950px){
      display: none;
    }
  }
  .header-middle{

    .header-gamburger{
      display: none;
      @include mediaMax(1000px){
        display: inline-block;
        position: relative;
        width: 40px;
        height: 30px;
        margin-top: 16px;
        margin-left: 30px;
        span{
          background-color: #ffffff;
          position: absolute;
          width: 100%;
          height: 6px;
          border-radius: 2px;
          &:nth-of-type(1){
            top: 0;
          }
          &:nth-of-type(2){
            top: 50%;
            margin-top: -3px;
          }
          &:nth-of-type(3){
            bottom: 0;
          }
        }
      }
      @include mediaMax(950px){

      }
    }
    .mobile-menu{
      display: none;
      @include mediaMax(1000px){
        position: absolute;
        left: 0;
        top: 100%;
        width: 200px;
        background: #c58200;
        padding: 20px;
        ul{
          float: none;
          width: 100%;
          li{
            width: 100%;
            height: auto;
            text-align: left;
            padding: 7px 0;
            &:before,
            &:after{
              display: none;
            }
          }
          &:last-of-type{
            li{
              width: 100%;
              height: auto;
            }
          }
        }
      }
      @include mediaMax(500px){
        width: 100%;
        right: 0;
        z-index: 1;
        top: 100px;
      }
      @include mediaMax(450px){
        top: 87px;
      }
      @include mediaMax(400px){
        top: 76px;
      }
    }

    .header-logo{
      position: absolute;
      top: -59px;
      left: 50%;
      transform: translateX(-50%);
      border-bottom: none;
      display: block;
      @include mediaMax(600px){
        width: 300px;
      }
      @include mediaMax(500px){
        width: 250px;
        top: -43px;
      }
      @include mediaMax(450px){
        width: 200px;
        top: -28px;
      }
      @include mediaMax(400px){
        width: 170px;
        top: -21px;
      }
    }
    .header-form{
      float: right;
    }
    @include mediaMax(950px){
      margin-top: 55px;
    }
    @include mediaMax(500px){
      margin-top: 42px;
    }
    @include mediaMax(450px){
      margin-top: 27px;
    }
    @include mediaMax(400px){
      margin-top: 20px;
    }
  }
  .header-bottom{
    padding-top: 10px;
    padding-bottom: 37px;
    @include clear();
    span{
      font-size: 15px;
      color: #ffffff;
      float: left;
      width: 38%;
      text-align: center;
      font-style: italic;
      @include mediaMax(950px){
        display: none;
      }
    }
    a{
      float: right;
      font-size: 15px;
      color: #ffffff;
      border-bottom: none;
      width: 46%;
      text-align: center;
      font-style: italic;
    }
    p{
      text-align: center;
      font-size: 15px;
      color: #ccc4c3;
      max-width: 510px;
      margin-left: auto;
      margin-right: auto;
      padding-top: 50px;
      line-height: 17px;
      img{
        display: block;
        margin-left: auto;
        margin-right: auto;
      }
      @include mediaMax(950px){
        padding-top: 35px;
        margin-bottom: 0;
        max-width: 90%;
        img{
          margin-bottom: 10px;
        }
      }
    }
    .header-bottom-mail{
      @include mediaMax(950px){
        display: none;
      }
    }
    .header-bottom-mobile{
      display: none;
      @include mediaMax(950px){
        display: block;
        text-align: center;
        margin-top: 60px;
        width: 310px;
        margin-left: auto;
        margin-right: auto;
        img{
          display: block;
          margin-left: auto;
          margin-right: auto;
        }
        .header-phone-mobile{
          display: inline-block;
          float: none;
          width: auto;
          margin-top: 5px;
        }
        .header-text-mobile{
          display: block;
          float: none;
          width: auto;
          margin-top: 12px;
        }
        .header-mail-mobile{
          float: none;
          width: auto;
          margin-top: 8px;
          display: inline-block;
        }
      }
      @include mediaMax(450px){
        margin-top: 30px;
      }
    }
  }
}

.index-slider-wrapper{
  width: 100%;
  overflow: hidden;
  position: relative;
  .container{
    @include mediaMax(950px){
      width: 100%;
      padding-left: 0;
      padding-right: 0;
    }
  }
}

.index-slider{
  //width: 489px;
  width: 140%;
  margin-left: -20%;
  //margin-left: auto;
  //margin-right: auto;
  //overflow: visible !important;
  @include mediaMax(500px){
    width: 100%;
    margin-left: 0;
  }
  .owl-stage-outer{
    overflow: visible !important;
  }
  .index-slide-item{
    overflow: hidden;
    img{
      filter: grayscale(1) blur(5px);
    }
  }
  .owl-item{
    &.active{
      .index-slide-item{
        img{
          filter: none;
        }
      }
    }
  }
  .slick-center{
    img{
      filter: none;
    }
  }
  .owl-nav{
    .owl-prev,
    .owl-next{
      position: absolute;
      top: 50%;
      right: -30px;
      margin-top: -30px;
      width: 60px;
      height: 60px;
      background: url("../images/slider-arrow.png") no-repeat center center,#482418;
      border-radius: 50%;
      @include mediaMax(600px){
        right: 0;
      }
    }
    .owl-prev{
      right: auto;
      left: -30px;
      transform: rotate(180deg);
      @include mediaMax(600px){
        left: 0;
      }
    }
  }
}

.index-slider-nav{
  width: 28%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  @include mediaMax(1200px){
    width: 47%;
  }
  @include mediaMax(500px){
    width: 98%;
  }
  .index-slider-nav-prev,
  .index-slider-nav-next{
    position: absolute;
    top: 50%;
    right: -30px;
    margin-top: -30px;
    width: 60px;
    height: 60px;
    background: url("../images/slider-arrow.png") no-repeat center center,#482418;
    border-radius: 50%;
    cursor: pointer;
    @include mediaMax(500px){
      right: 0;
    }
  }
  .index-slider-nav-prev{
    right: auto;
    left: -30px;
    transform: rotate(180deg);
    @include mediaMax(500px){
      left: 0;
    }
  }
}

.index-slider-controls{
  width: 445px;
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 35px;
  @include mediaMax(489px){
    width: 100%;
  }
  .owl-item{
    cursor: pointer;
    img{
      filter: grayscale(1);
    }
    &.current{
      img{
        filter: none;
      }
    }
  }
  .index-slide-control-item{
    img{
      cursor: pointer;
      filter: grayscale(1);
    }
  }
  .slick-center{
    img{
      filter: none;
    }
  }
}

.index-advantage{
  margin-top: 40px;
  background-color: #fff3db;
  position: relative;
  &:before{
    content: '';
    display: block;
    width: 13px;
    height: 100%;
    background-color: #fff3db;
    position: absolute;
    top: 0;
    left: -13px;
  }
  &:after{
    content: '';
    display: block;
    width: 13px;
    height: 100%;
    background-color: #fff3db;
    position: absolute;
    top: 0;
    right: -13px;
  }
  @include mediaMax(950px){
    &:before,
    &:after{
      display: none;
    }
  }
  @include mediaMax(500px){
    margin-top: 30px;
  }
  @include mediaMax(400px){
    margin-top: 22px;
  }
  .index-advantage-tit{
    img{
      display: block;
      margin-left: auto;
      margin-right: auto;
      position: relative;
      top: -40px;
    }
    @include mediaMax(500px){
      img{
        top: -30px;
      }
    }
    @include mediaMax(400px){
      img{
        top: -20px;
      }
    }
  }
  .index-advantage-list{
    display: flex;
    flex-wrap: wrap;
    margin-top: -20px;
    &:before{
      content: '';
      width: 13px;
      height: 13px;
      display: block;
      position: absolute;
      left: -13px;
      bottom: -13px;
      border: 6px solid #5b3b11;
      border-left-color: transparent;
      border-bottom-color: transparent;
      box-sizing: border-box;
    }
    &:after{
      content: '';
      width: 13px;
      height: 13px;
      display: block;
      position: absolute;
      right: -13px;
      bottom: -13px;
      border: 6px solid #5b3b11;
      border-right-color: transparent;
      border-bottom-color: transparent;
      box-sizing: border-box;
    }
    @include mediaMax(950px){
      &:before,
      &:after{
        display: none;
      }
    }
    .index-advantage-item{
      width: 25%;
      padding: 0 5px;
      text-align: center;
      img{
        margin-bottom: 20px;
      }
      p{
        color: #000000;
        font-weight: 400;
        text-align: center;
      }
      @include mediaMax(750px){
        width: 50%;
        padding: 0 10px;
      }
      @include mediaMax(500px){
        width: 100%;
      }
    }
  }
}

.index-services{
  padding-bottom: 55px;
  .index-services-tit{
    text-align: center;
    margin-top: 35px;
  }
  .index-services-list{
    text-align: center;
    padding-top: 20px;
    .index-services-item{
      display: inline-block;
      vertical-align: top;
      padding: 0 32px;
      img{
        display: block;
        margin-bottom: 7px;
        margin-left: auto;
        margin-right: auto;
      }
      a{
        text-align: center;
        display: block;
        line-height: 15px;
        border-bottom:  none;
        font-size: 15px;
        color: #ffffff;
        &:last-of-type{
          display: inline-block;
          max-width: 220px;
        }
      }
      @include mediaMax(800px){
        padding-left: 10px;
        padding-right: 10px;
      }
      @include mediaMax(700px){
        width: 60%;
        margin-bottom: 30px;
        text-align: center;
      }
      @include mediaMax(500px){
        width: auto;
      }
    }
  }
}

.main-footer{
  padding: 15px 0;
  background: url("../images/footer-bg.png") repeat  left top;
  @include mediaMax(750px){
    padding-top: 25px;
  }
  .container{
    background: none;
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    .footer-left{
      display: flex;
      @include mediaMax(750px){
        width: 100%;
        margin-bottom: 30px;
        justify-content: center;
      }
      .footer-logo{
        display: block;
        border-bottom: none;
        padding-right: 20px;
        margin-right: 23px;
        border-right: 1px solid #FFFFFF;
        img{
          display: block;
        }
      }
    }
    .footer-center{
      text-align: center;
      @include mediaMax(500px){
        width: 100%;
        margin-bottom: 30px;
        justify-content: center;
      }
      .footer-phone{
        font-size: 29px;
        font-weight: 500;
        color: #ffffff;
        border-bottom: none;
        font-style: italic;
        display: block;
        margin-bottom: 5px;
        text-align: center;
      }
      .footer-mail{
        border-bottom: none;
        color: #ffffff;
        font-style: italic;
        font-size: 15px;

      }
    }
    .footer-right{
      max-width: 200px;
      text-align: center;
      span{
        color: #ffffff;
        font-size: 15px;
      }
      @include mediaMax(500px){
        width: 100%;
        margin-bottom: 30px;
        justify-content: center;
        margin-bottom: 10px;
      }
      .footer-social-block{
        margin-top: 10px;
        a{
          border-bottom: none;
          display: inline-block;
          vertical-align: middle;
          margin-right: 15px;
          &:last-of-type{
            margin-right: 0;
          }
        }
      }
    }
  }
}

.inner-page-gallery{
  .col-2{
    padding: 0 5px;
    @include mediaMax(500px){
      float: none;
      width: 100%;
      overflow: auto;
    }
  }
  .col-10{
    padding: 0 5px;
    @include mediaMax(500px){
      float: none;
      width: 100%;
    }
  }
  .inner-page-gallery-preview-wrapper{
    @include mediaMax(500px){
      white-space: nowrap;
      margin-bottom: 7px;
    }
    .inner-page-gallery-preview-item{
      margin-bottom: 10px;
      position: relative;
      cursor: pointer;
      height: 81px;
      background-position: center;
      @include mediaMax(500px){
        display: inline-block;
        vertical-align: top;
        margin-right: 5px;
        margin-bottom: 0;
      }
      &:after{
        content: '';
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        @include transition();
      }
      img{
        display: block;
        margin-left: auto;
        margin-right: 0;
        filter: grayscale(1);
        @include transition();
      }
      &.is-active{
        cursor: auto;
        &:after{
          box-shadow: inset 0 0 0 7px #e59f2d;
        }
        img{
          filter: none;
        }
      }
    }
  }
  .inner-page-gallery-big-wrapper{
    position: relative;
    height: 445px;
    @include mediaMax(500px){
      height: auto;
      padding-bottom: 57%;
    }
    .inner-page-gallery-big-item{
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      opacity: 0;
      background-position: center;
      @include mediaMax(500px){
        background-size: 100% auto;
      }
      @include transition();
      img{
        display: block;
        max-width: 1000px;
        max-height: 1000px;
      }
      &.is-active{
        opacity: 1;
      }
    }
  }
  .inner-page-gallery-nav{
    .inner-page-gallery-nav-prev,
    .inner-page-gallery-nav-next{
      width: 38px;
      height: 64px;
      position: absolute;
      top: 50%;
      margin-top: -32px;
      right: 20px;
      background: url("../images/slider-next.png") no-repeat center center;
      cursor: pointer;
    }
    .inner-page-gallery-nav-prev{
      right: auto;
      left: 20px;
      background: url("../images/slider-prev.png") no-repeat center center;
    }
  }
}

.inner-page-form{
  margin-bottom: 30px;
  .inner-page-form-tit{
    display: block;
    text-align: center;
    margin-bottom: 15px;
  }
  textarea{
    height: 123px;
  }
  [type="submit"]{
    margin-left: auto;
    margin-right: auto;
    width: 140px;
    display: block;
    margin-top: -10px;
  }
}

.inner-page-text-btn-block{
  border: 1px solid #e59f2d;
  padding: 20px;
  margin: 20px 0;
  p{
    margin-bottom: 5px;
  }
  .button{
    margin-top: 4px;
    display: inline-block;
    padding-top: 12px;
    height: auto;
    text-align: center;
    padding-bottom: 12px;
  }
}

.inner-page-inline-blocks{
  display: flex;
  flex-wrap: wrap;
  margin-left: -25px;
  margin-right: -25px;
  .inner-page-inline-block-item{
    position: relative;
    width: 20%;
    padding: 0 25px;
    &:after{
      content: '';
      display: block;
      width: 40px;
      height: 40px;
      position: absolute;
      top: 50%;
      margin-top: -20px;
      right: -20px;
      background: url("../images/right-arrow.png") no-repeat center center;
      background-size: 100%;
    }
    &:last-of-type{
      &:after{
        display: none;
      }
    }
    p{
      display: flex;
      align-items: center;
      justify-content: center;
      width: calc(100% - 40px);
      background-color: #e59f2d;
      border-radius: 5px;
      padding: 10px;
      text-align: center;
      height: 100%;
    }
    @include mediaMinMax(500px,800px){
      width: 33.333%;
      text-align: center;
      margin-bottom: 15px;
      &:nth-of-type(3){
        &:after{
          display: none;
        }
      }
    }
    @include mediaMax(500px){
      width: 50%;
      text-align: center;
      margin-bottom: 15px;
      &:nth-of-type(2),
      &:nth-of-type(4){
        &:after{
          display: none;
        }
      }
    }
  }
}

.inner-page-form-btn-wrapper{
  text-align: center;
  margin: 10px 0;
  .button{
    display: inline-block;
    height: auto;
    padding: 12px 15px;
  }
}

.contact-tit{
  font-size: 18px;
}
.contact-text{
  span{
    display: block;
  }
}

.contact-map{
  height: 450px;
}

.print-btn-wrapper{
  @include clear();
  p{
    display: inline-block;
    @include mediaMax(450px){
      display: block;
    }
  }
  .print-btn{
    float: right;
    position: relative;
    margin-left: 25px;
    &:before{
      content: '';
      position: absolute;
      top: 5px;
      left: -25px;
      display: block;
      width: 16px;
      height: 16px;
      background: url("../images/print.png") no-repeat center;
    }
    @include mediaMax(450px){
      float: none;
      margin-bottom: 10px;
      display: inline-block;
    }
  }
}

.inner-top-menu{
  border-top: 1px solid #e59f2d;
  border-bottom: 1px solid #e59f2d;
  padding: 5px 0 0;
  ul{
    list-style: none;
    margin: 0;
    padding: 0;
    font-size: 0;
    display: block;
    width: 100%;
    text-align: center;
    li{
      position: relative;
      display: inline-block;
      vertical-align: middle;
      padding: 15px 15px;
      margin-right: 10px;
      max-width: 100%;
      margin-bottom: 5px;
      a{
        border-bottom: none;
        color: #ffffff;
      }
      &.current,
      &:hover{
        background-color: #e59f2d;
        &:after{
          content: '';
          position: absolute;
          top: 0;
          right: -10px;
          display: block;
          width: 10px;
          height: 100%;
          border-left: 10px solid #e59f2d;
          border-top: 26px solid transparent;
          border-bottom: 26px solid transparent;
          box-sizing: border-box;
        }
        a{
          display: inline-block;
          color: #000000;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          max-width: 100%;
        }
      }
    }
  }
}

.values-slider{
  margin-top: 15px;
  margin-bottom: 30px;
  &.owl-carousel{
    padding: 0 50px;
    @include mediaMax(500px){
      padding: 0;
    }
  }
  .values-slider-item{
    width: 33%;
    float: left;
    padding: 0 10px;
    .values-slider-item-img{
      height: 130px;
      text-align: center;
      &:before{
        content: '';
        width: 0;
        height: 100%;
        display: inline-block;
        vertical-align: middle;
      }
    }
    img{
      display: inline-block;
      vertical-align: middle;
      max-height: 100%;
      max-width: 100%;
    }
    .values-slider-item-tit{
      font-size: 20px;
      display: block;
      text-align: center;
      margin: 10px 0;
    }
    p{
      margin: 0;
    }
  }
  .owl-item{
    img{
      width: auto;
    }
    .values-slider-item{
      width: 100%;
      float: none;
    }
  }
  .owl-nav{
  }
  .owl-prev,
  .owl-next{
    width: 46px;
    height: 84px;
    position: absolute;
    top: 50%;
    right: 0;
    margin-top: -42px;
    background: url("../images/value-slider-arrow-next.png");
  }
  .owl-prev{
    left: 0;
    right: auto;
    background: url("../images/value-slider-arrow-prev.png");
  }
  .owl-dots{
    margin-top: 20px;
    text-align: center;
    .owl-dot{
      display: inline-block;
      vertical-align: middle;
      margin-right: 8px;
      width: 10px;
      height: 10px;
      border-radius: 50%;
      border: 3px solid #ffffff;
      transition: transform 0.3s, border 0.3s;
      &.active{
        transform: scale(1.4);
        border: 2px solid #ffffff;
      }
      &:last-of-type{
        margin-right: 0;
      }
    }
  }
}

.infograf-b{
  display: flex;
  flex-wrap: wrap;
  margin: 20px -20px 30px;
  .infograf-b-item{
    position: relative;
    width: 25%;
    padding: 0 20px;
    text-align: center;
    &:after{
      content: '';
      display: block;
      position: absolute;
      top: 50%;
      right: -32px;
      margin-top: -19px;
      width: 65px;
      height: 38px;
      background: url("../images/b-arrow.png") no-repeat center center;
      background-size: 100%;
    }
    &:last-of-type{
      &:after{
        display: none;
      }
    }
    @include mediaMax(750px){
      width: 50%;
      &:nth-of-type(2){
        &:after{
          display: none;
        }
      }
    }
    @include mediaMax(400px){
      &:after{
        width: 40px;
        height: 30px;
        margin-top: -15px;
        right: -20px;
      }
    }
  }
}

.fancybox-gallery-wrapper{
  display: flex;
  flex-wrap: wrap;
  font-size: 0;
  .fancybox-gallery-item{
    display: inline-block;
    width: 33.333%;
    padding: 10px;
    a{
      border-bottom: none;
      display: block;
      margin-left: auto;
      margin-right: auto;
      img{
        display: block;
        margin-left: auto;
        margin-right: auto;
      }
    }
    @include mediaMax(700px){
      width: 50%;
    }
    @include mediaMax(500px){
      width: 100%;
    }
  }
}

.fancybox-close{
  border-bottom: none;
}
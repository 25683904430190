.main-wrapper{
  overflow: hidden;
}
.container{
  width: calc(100% - 40px);
  max-width: 990px;
  margin: 0 auto;
  //background-color: #3f221d;
  background: url("../images/container-bg.png") repeat left top;
  padding-top: 1px;
    @include mediaMax(950px){
      width: 100%;
      padding: 1px 20px;
    }
}

.content-wrapper{
  .container{
    padding: 1px 20px 70px;
  }
}
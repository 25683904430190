.button{
  border: none;
  background-color: #e59f2d;
  border-radius: 3px;
  height: 46px;
  padding-left: 20px;
  padding-right: 20px;
  color: #ffffff;
  text-transform: uppercase;
  font-size: 16px;
  transition: 0.2s;
  font-weight: 300;
  &:hover{
    background-color: #ac702b;
  }
}
.btn-scroll-top{
  display: none;
  position: fixed;
  right: 40px;
  top: 50%;
  margin-top: -27px;
  width: 23px;
  height: 54px;
  cursor: pointer;
  z-index: 20;
  &.is-active{
    display: block;
  }
}

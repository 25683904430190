.header-menu{
  position: relative;
  background: url("../images/menu-bg.png") repeat left top,linear-gradient(to top,#dc9a17,#eeb942);
  height: 65px;
  &:before{
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: -59px;
    width: 59px;
    height: 80px;
    background: url("../images/menu-left.png") no-repeat center center;
  }
  &:after{
    content: '';
    display: block;
    position: absolute;
    top: 0;
    right: -59px;
    width: 59px;
    height: 80px;
    background: url("../images/menu-right.png") no-repeat center center;
  }
  @include mediaMax(950px){
    background: #e2a226;
  }
  @include clear();
  ul{
    margin: 0;
    padding: 0;
    list-style: none;
    width: calc(50% - 155px);
    &:first-of-type{
      float: left;
    }
    &:last-of-type{
      float: right;
      width: calc(37% - 155px);
    }

    li{
      position: relative;
      display: inline-block;
      width: 32.5%;
      text-align: center;
      vertical-align: middle;
      line-height: 17px;
      height: 65px;
      &:before{
        content: '';
        display: inline-block;
        vertical-align: middle;
        width: 0;
        height: 100%;
      }
      &:after{
        content: '';
        display: inline-block;
        position: absolute;
        right: 0;
        top: 0;
        width: 1px;
        height: 100%;
        background-color: #000000;
        border: 1px solid #ffffff;
        opacity: 0.1;
      }
      &:last-of-type{
        &:after{
          display: none;
        }
      }
      a{
        display: inline-block;
        vertical-align: middle;
        font-size: 17px;
        line-height: 17px;
        color: #ffffff;
        border-bottom: none;
        font-weight: 300;
        text-transform: uppercase;
      }
    }
    &:last-of-type{
      li{
        &:nth-of-type(1),
        &:nth-of-type(2){
          width: 49%;
        }
        &:nth-of-type(2){
          &:after{
            display: none;
          }
        }
      }
    }
  }
}

.header-form {
  float: right;
  position: relative;
  width: 14%;
  padding: 13px 13px 10px 23px;
  &:after {
    content: '';
    position: absolute;
    top: -6px;
    left: 0px;
    display: block;
    width: 100%;
    height: 77px;
    background: url("../images/form-btn.png") no-repeat left top;
    background-size: 100% 100%;
    opacity: 1;
    border: none;
  }
  a{
    display: inline-block;
    vertical-align: middle;
    font-size: 17px;
    line-height: 17px;
    color: #ffffff;
    border-bottom: none;
    font-weight: 300;
    text-transform: uppercase;
    position: relative;
    z-index: 1;
    text-align: center;
  }
  @include mediaMax(950px){
    margin-right: 12%;
    width: 139px;
  }
  @include mediaMax(850px){
    margin-right: 9%;
  }
  @include mediaMax(750px){
    margin-right: 7%;
  }
  @include mediaMax(700px){
    margin-right: 7%;
    width: 80px;
    padding: 15px 13px 10px 12px;
    a{
      font-size: 12px;
    }
  }
  @include mediaMax(550px){
    margin-right: 5%;
  }
  @include mediaMax(500px){
    margin-right: 5%;
    width: 70px;
    padding: 18px 13px 10px 9px;
    a{
      font-size: 11px;
      line-height: 13px;
    }
  }
  @include mediaMax(350px){
    margin-right: 2%;
  }
}

.footer-menu{
  ul{
    margin: 0;
    padding: 0;
    list-style: none;
    li{
      display: block;
      a{
        font-size: 15px;
        color: #ffffff;
        border-bottom: none;
      }
    }
  }
}
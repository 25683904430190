@charset "UTF-8";
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, img, ins, kbd, q, s, samp,
small, strike, sub, sup, tt, var, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline; }

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block; }

body {
  line-height: 1; }

blockquote, q {
  quotes: none; }

blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none; }

html {
  font-family: sans-serif;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%; }

body {
  margin: 0; }

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
main,
menu,
nav,
section,
summary {
  display: block; }

audio,
canvas,
progress,
video {
  display: inline-block;
  vertical-align: baseline; }

audio:not([controls]) {
  display: none;
  height: 0; }

[hidden],
template {
  display: none; }

a {
  background-color: transparent; }

a:active,
a:hover {
  outline: 0; }

abbr[title] {
  border-bottom: 1px dotted; }

b,
strong {
  font-weight: bold; }

dfn {
  font-style: italic; }

h1 {
  font-size: 2em;
  margin: 0.67em 0; }

mark {
  background: #ff0;
  color: #000; }

small {
  font-size: 80%; }

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline; }

sup {
  top: -0.5em; }

sub {
  bottom: -0.25em; }

img {
  border: 0; }

svg:not(:root) {
  overflow: hidden; }

figure {
  margin: 1em 40px; }

hr {
  box-sizing: content-box;
  height: 0; }

pre {
  overflow: auto; }

code,
kbd,
pre,
samp {
  font-family: monospace, monospace;
  font-size: 1em; }

button,
input,
optgroup,
select,
textarea {
  color: inherit;
  font: inherit;
  margin: 0; }

button {
  overflow: visible; }

button,
select {
  text-transform: none; }

button,
html input[type="button"],
input[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
  cursor: pointer; }

button[disabled],
html input[disabled] {
  cursor: default; }

button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0; }

input {
  line-height: normal; }

input[type="checkbox"],
input[type="radio"] {
  box-sizing: border-box;
  padding: 0; }

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  height: auto; }

input[type="search"] {
  -webkit-appearance: textfield;
  box-sizing: content-box; }

input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

fieldset {
  border: 1px solid #c0c0c0;
  margin: 0 2px;
  padding: 0.35em 0.625em 0.75em; }

legend {
  border: 0;
  padding: 0; }

textarea {
  overflow: auto; }

optgroup {
  font-weight: bold; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

td,
th {
  padding: 0; }

* {
  outline: none;
  box-sizing: border-box;
  padding: 0;
  margin: 0; }

html, body {
  font-family: "Open Sans", serif;
  font-size: 15px;
  color: #ffffff;
  font-weight: 400;
  line-height: 1.42857;
  background: url("../images/body-bg.png") repeat left top, #341c18; }

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  font-family: "Open Sans", serif;
  font-weight: 300;
  line-height: 1.1;
  color: #ffffff;
  text-align: center; }
  @media only screen and (max-width: 400px) {
    h1, h2, h3, h4, h5, h6,
    .h1, .h2, .h3, .h4, .h5, .h6 {
      hyphens: auto; } }

h1, .h1 {
  font-size: 40px;
  color: #ffffff; }
  @media only screen and (max-width: 800px) {
    h1, .h1 {
      font-size: 34px; } }
  @media only screen and (max-width: 400px) {
    h1, .h1 {
      font-size: 30px; } }

h2, .h2 {
  font-size: 32px;
  color: #ffffff;
  margin: 25px 0 20px; }
  @media only screen and (max-width: 800px) {
    h2, .h2 {
      font-size: 30px; } }
  @media only screen and (max-width: 400px) {
    h2, .h2 {
      font-size: 26px; } }

h3, .h3 {
  font-size: 28px;
  color: #ffffff;
  margin: 25px 0 20px; }
  @media only screen and (max-width: 800px) {
    h3, .h3 {
      font-size: 28px; } }
  @media only screen and (max-width: 400px) {
    h3, .h3 {
      font-size: 22px; } }

h4, .h4 {
  font-size: 24px;
  color: #ffffff;
  margin: 15px 0 10px; }
  @media only screen and (max-width: 800px) {
    h4, .h4 {
      font-size: 22px; } }
  @media only screen and (max-width: 400px) {
    h4, .h4 {
      font-size: 18px; } }

h5, .h5 {
  font-size: 18px;
  color: #ffffff; }

h6, .h6 {
  font-size: 16px;
  color: #ffffff; }

p {
  line-height: 22px;
  font-size: 16px;
  margin-bottom: 26px;
  color: #ffffff;
  font-weight: 300;
  text-align: left; }

a {
  font-size: 15px;
  color: #e59f2d;
  text-decoration: none;
  border-bottom: 1px solid #e59f2d;
  -webkit-transition: all linear 0.2s 0s;
  -moz-transition: all linear 0.2s 0s;
  -ms-transition: all linear 0.2s 0s;
  -o-transition: all linear 0.2s 0s;
  transition: all linear 0.2s 0s;
  font-weight: 300; }
  a:hover {
    border-bottom-color: transparent; }

img {
  border: none;
  max-width: 100%;
  max-height: 100%; }

table {
  border: 1px solid #000000;
  padding: 0;
  margin: 40px 0 30px; }
  table td, table th {
    border: 1px solid #000000;
    font-weight: 300; }
  table th {
    text-align: center; }

ul, ol {
  margin: 20px 0;
  list-style-position: inside; }
  ul li, ol li {
    color: #ffffff;
    font-weight: 300;
    padding: 3px 0; }

.nice_table {
  border-collapse: collapse;
  font-size: 14px; }
  .nice_table th, .nice_table td {
    border: 1px solid #d1e0e8;
    color: #0d0d0d;
    font-size: 15px; }
  .nice_table th {
    background: #eff0f4;
    font-size: 16px; }
  .nice_table tr:nth-child(even) {
    background: #eff0f4; }

.with_marker {
  list-style-image: url("../images/with_marker.png");
  list-style-position: outside;
  margin: 33px 0 40px 24px; }
  .with_marker li {
    line-height: 24px; }

.error {
  clear: both; }
  .error ul {
    list-style: none;
    margin: 0;
    padding: 0; }
    .error ul li {
      padding: 3px 0 0 40px;
      font-size: 14px;
      color: red; }

table.noborder {
  border: none; }
  table.noborder td, table.noborder th {
    border: none; }

table.cellpadding_5 td, table.cellpadding_5 th {
  padding: 5px; }

table.cellpadding_7 td, table.cellpadding_7 th {
  padding: 7px; }

table.cellpadding_10 td, table.cellpadding_10 th {
  padding: 10px; }

table.justify {
  width: 100%; }

.table-wrapper {
  overflow: auto;
  width: 100%; }
  .table-wrapper .table-wrapper {
    overflow: visible; }

.title,
.title_pole, .title_field {
  display: none !important; }

.row {
  margin-left: -14px;
  margin-right: -14px; }
  .row:after {
    content: '';
    display: block;
    clear: both; }

.col-xs-1, .col-sm-1, .col-md-1, .col-lg-1, .col-container-1, .col-1, .col-xs-2, .col-sm-2, .col-md-2, .col-lg-2, .col-container-2, .col-2, .col-xs-3, .col-sm-3, .col-md-3, .col-lg-3, .col-container-3, .col-3, .col-xs-4, .col-sm-4, .col-md-4, .col-lg-4, .col-container-4, .col-4, .col-xs-5, .col-sm-5, .col-md-5, .col-lg-5, .col-container-5, .col-5, .col-xs-6, .col-sm-6, .col-md-6, .col-lg-6, .col-container-6, .col-6, .col-xs-7, .col-sm-7, .col-md-7, .col-lg-7, .col-container-7, .col-7, .col-xs-8, .col-sm-8, .col-md-8, .col-lg-8, .col-container-8, .col-8, .col-xs-9, .col-sm-9, .col-md-9, .col-lg-9, .col-container-9, .col-9, .col-xs-10, .col-sm-10, .col-md-10, .col-lg-10, .col-container-10, .col-10, .col-xs-11, .col-sm-11, .col-md-11, .col-lg-11, .col-container-11, .col-11, .col-xs-12, .col-sm-12, .col-md-12, .col-lg-12, .col-container-12, .col-12 {
  position: relative;
  min-height: 1px;
  padding-left: 14px;
  padding-right: 14px; }

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12 {
  float: left; }

.col-1 {
  width: 8.33333%; }

.col-2 {
  width: 16.66667%; }

.col-3 {
  width: 25%; }

.col-4 {
  width: 33.33333%; }

.col-5 {
  width: 41.66667%; }

.col-6 {
  width: 50%; }

.col-7 {
  width: 58.33333%; }

.col-8 {
  width: 66.66667%; }

.col-9 {
  width: 75%; }

.col-10 {
  width: 83.33333%; }

.col-11 {
  width: 91.66667%; }

.col-12 {
  width: 100%; }

.col-pull-0 {
  right: auto; }

.col-pull-1 {
  right: 8.33333%; }

.col-pull-2 {
  right: 16.66667%; }

.col-pull-3 {
  right: 25%; }

.col-pull-4 {
  right: 33.33333%; }

.col-pull-5 {
  right: 41.66667%; }

.col-pull-6 {
  right: 50%; }

.col-pull-7 {
  right: 58.33333%; }

.col-pull-8 {
  right: 66.66667%; }

.col-pull-9 {
  right: 75%; }

.col-pull-10 {
  right: 83.33333%; }

.col-pull-11 {
  right: 91.66667%; }

.col-pull-12 {
  right: 100%; }

.col-push-0 {
  left: auto; }

.col-push-1 {
  left: 8.33333%; }

.col-push-2 {
  left: 16.66667%; }

.col-push-3 {
  left: 25%; }

.col-push-4 {
  left: 33.33333%; }

.col-push-5 {
  left: 41.66667%; }

.col-push-6 {
  left: 50%; }

.col-push-7 {
  left: 58.33333%; }

.col-push-8 {
  left: 66.66667%; }

.col-push-9 {
  left: 75%; }

.col-push-10 {
  left: 83.33333%; }

.col-push-11 {
  left: 91.66667%; }

.col-push-12 {
  left: 100%; }

.col-offset-0 {
  margin-left: 0%; }

.col-offset-1 {
  margin-left: 8.33333%; }

.col-offset-2 {
  margin-left: 16.66667%; }

.col-offset-3 {
  margin-left: 25%; }

.col-offset-4 {
  margin-left: 33.33333%; }

.col-offset-5 {
  margin-left: 41.66667%; }

.col-offset-6 {
  margin-left: 50%; }

.col-offset-7 {
  margin-left: 58.33333%; }

.col-offset-8 {
  margin-left: 66.66667%; }

.col-offset-9 {
  margin-left: 75%; }

.col-offset-10 {
  margin-left: 83.33333%; }

.col-offset-11 {
  margin-left: 91.66667%; }

.col-offset-12 {
  margin-left: 100%; }

@media (min-width: 400px) {
  .col-xs-1, .col-xs-2, .col-xs-3, .col-xs-4, .col-xs-5, .col-xs-6, .col-xs-7, .col-xs-8, .col-xs-9, .col-xs-10, .col-xs-11, .col-xs-12 {
    float: left; }
  .col-xs-1 {
    width: 8.33333%; }
  .col-xs-2 {
    width: 16.66667%; }
  .col-xs-3 {
    width: 25%; }
  .col-xs-4 {
    width: 33.33333%; }
  .col-xs-5 {
    width: 41.66667%; }
  .col-xs-6 {
    width: 50%; }
  .col-xs-7 {
    width: 58.33333%; }
  .col-xs-8 {
    width: 66.66667%; }
  .col-xs-9 {
    width: 75%; }
  .col-xs-10 {
    width: 83.33333%; }
  .col-xs-11 {
    width: 91.66667%; }
  .col-xs-12 {
    width: 100%; }
  .col-xs-pull-0 {
    right: auto; }
  .col-xs-pull-1 {
    right: 8.33333%; }
  .col-xs-pull-2 {
    right: 16.66667%; }
  .col-xs-pull-3 {
    right: 25%; }
  .col-xs-pull-4 {
    right: 33.33333%; }
  .col-xs-pull-5 {
    right: 41.66667%; }
  .col-xs-pull-6 {
    right: 50%; }
  .col-xs-pull-7 {
    right: 58.33333%; }
  .col-xs-pull-8 {
    right: 66.66667%; }
  .col-xs-pull-9 {
    right: 75%; }
  .col-xs-pull-10 {
    right: 83.33333%; }
  .col-xs-pull-11 {
    right: 91.66667%; }
  .col-xs-pull-12 {
    right: 100%; }
  .col-xs-push-0 {
    left: auto; }
  .col-xs-push-1 {
    left: 8.33333%; }
  .col-xs-push-2 {
    left: 16.66667%; }
  .col-xs-push-3 {
    left: 25%; }
  .col-xs-push-4 {
    left: 33.33333%; }
  .col-xs-push-5 {
    left: 41.66667%; }
  .col-xs-push-6 {
    left: 50%; }
  .col-xs-push-7 {
    left: 58.33333%; }
  .col-xs-push-8 {
    left: 66.66667%; }
  .col-xs-push-9 {
    left: 75%; }
  .col-xs-push-10 {
    left: 83.33333%; }
  .col-xs-push-11 {
    left: 91.66667%; }
  .col-xs-push-12 {
    left: 100%; }
  .col-xs-offset-0 {
    margin-left: 0%; }
  .col-xs-offset-1 {
    margin-left: 8.33333%; }
  .col-xs-offset-2 {
    margin-left: 16.66667%; }
  .col-xs-offset-3 {
    margin-left: 25%; }
  .col-xs-offset-4 {
    margin-left: 33.33333%; }
  .col-xs-offset-5 {
    margin-left: 41.66667%; }
  .col-xs-offset-6 {
    margin-left: 50%; }
  .col-xs-offset-7 {
    margin-left: 58.33333%; }
  .col-xs-offset-8 {
    margin-left: 66.66667%; }
  .col-xs-offset-9 {
    margin-left: 75%; }
  .col-xs-offset-10 {
    margin-left: 83.33333%; }
  .col-xs-offset-11 {
    margin-left: 91.66667%; }
  .col-xs-offset-12 {
    margin-left: 100%; } }

@media (min-width: 768px) {
  .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12 {
    float: left; }
  .col-sm-1 {
    width: 8.33333%; }
  .col-sm-2 {
    width: 16.66667%; }
  .col-sm-3 {
    width: 25%; }
  .col-sm-4 {
    width: 33.33333%; }
  .col-sm-5 {
    width: 41.66667%; }
  .col-sm-6 {
    width: 50%; }
  .col-sm-7 {
    width: 58.33333%; }
  .col-sm-8 {
    width: 66.66667%; }
  .col-sm-9 {
    width: 75%; }
  .col-sm-10 {
    width: 83.33333%; }
  .col-sm-11 {
    width: 91.66667%; }
  .col-sm-12 {
    width: 100%; }
  .col-sm-pull-0 {
    right: auto; }
  .col-sm-pull-1 {
    right: 8.33333%; }
  .col-sm-pull-2 {
    right: 16.66667%; }
  .col-sm-pull-3 {
    right: 25%; }
  .col-sm-pull-4 {
    right: 33.33333%; }
  .col-sm-pull-5 {
    right: 41.66667%; }
  .col-sm-pull-6 {
    right: 50%; }
  .col-sm-pull-7 {
    right: 58.33333%; }
  .col-sm-pull-8 {
    right: 66.66667%; }
  .col-sm-pull-9 {
    right: 75%; }
  .col-sm-pull-10 {
    right: 83.33333%; }
  .col-sm-pull-11 {
    right: 91.66667%; }
  .col-sm-pull-12 {
    right: 100%; }
  .col-sm-push-0 {
    left: auto; }
  .col-sm-push-1 {
    left: 8.33333%; }
  .col-sm-push-2 {
    left: 16.66667%; }
  .col-sm-push-3 {
    left: 25%; }
  .col-sm-push-4 {
    left: 33.33333%; }
  .col-sm-push-5 {
    left: 41.66667%; }
  .col-sm-push-6 {
    left: 50%; }
  .col-sm-push-7 {
    left: 58.33333%; }
  .col-sm-push-8 {
    left: 66.66667%; }
  .col-sm-push-9 {
    left: 75%; }
  .col-sm-push-10 {
    left: 83.33333%; }
  .col-sm-push-11 {
    left: 91.66667%; }
  .col-sm-push-12 {
    left: 100%; }
  .col-sm-offset-0 {
    margin-left: 0%; }
  .col-sm-offset-1 {
    margin-left: 8.33333%; }
  .col-sm-offset-2 {
    margin-left: 16.66667%; }
  .col-sm-offset-3 {
    margin-left: 25%; }
  .col-sm-offset-4 {
    margin-left: 33.33333%; }
  .col-sm-offset-5 {
    margin-left: 41.66667%; }
  .col-sm-offset-6 {
    margin-left: 50%; }
  .col-sm-offset-7 {
    margin-left: 58.33333%; }
  .col-sm-offset-8 {
    margin-left: 66.66667%; }
  .col-sm-offset-9 {
    margin-left: 75%; }
  .col-sm-offset-10 {
    margin-left: 83.33333%; }
  .col-sm-offset-11 {
    margin-left: 91.66667%; }
  .col-sm-offset-12 {
    margin-left: 100%; } }

@media (min-width: 1024px) {
  .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12 {
    float: left; }
  .col-md-1 {
    width: 8.33333%; }
  .col-md-2 {
    width: 16.66667%; }
  .col-md-3 {
    width: 25%; }
  .col-md-4 {
    width: 33.33333%; }
  .col-md-5 {
    width: 41.66667%; }
  .col-md-6 {
    width: 50%; }
  .col-md-7 {
    width: 58.33333%; }
  .col-md-8 {
    width: 66.66667%; }
  .col-md-9 {
    width: 75%; }
  .col-md-10 {
    width: 83.33333%; }
  .col-md-11 {
    width: 91.66667%; }
  .col-md-12 {
    width: 100%; }
  .col-md-pull-0 {
    right: auto; }
  .col-md-pull-1 {
    right: 8.33333%; }
  .col-md-pull-2 {
    right: 16.66667%; }
  .col-md-pull-3 {
    right: 25%; }
  .col-md-pull-4 {
    right: 33.33333%; }
  .col-md-pull-5 {
    right: 41.66667%; }
  .col-md-pull-6 {
    right: 50%; }
  .col-md-pull-7 {
    right: 58.33333%; }
  .col-md-pull-8 {
    right: 66.66667%; }
  .col-md-pull-9 {
    right: 75%; }
  .col-md-pull-10 {
    right: 83.33333%; }
  .col-md-pull-11 {
    right: 91.66667%; }
  .col-md-pull-12 {
    right: 100%; }
  .col-md-push-0 {
    left: auto; }
  .col-md-push-1 {
    left: 8.33333%; }
  .col-md-push-2 {
    left: 16.66667%; }
  .col-md-push-3 {
    left: 25%; }
  .col-md-push-4 {
    left: 33.33333%; }
  .col-md-push-5 {
    left: 41.66667%; }
  .col-md-push-6 {
    left: 50%; }
  .col-md-push-7 {
    left: 58.33333%; }
  .col-md-push-8 {
    left: 66.66667%; }
  .col-md-push-9 {
    left: 75%; }
  .col-md-push-10 {
    left: 83.33333%; }
  .col-md-push-11 {
    left: 91.66667%; }
  .col-md-push-12 {
    left: 100%; }
  .col-md-offset-0 {
    margin-left: 0%; }
  .col-md-offset-1 {
    margin-left: 8.33333%; }
  .col-md-offset-2 {
    margin-left: 16.66667%; }
  .col-md-offset-3 {
    margin-left: 25%; }
  .col-md-offset-4 {
    margin-left: 33.33333%; }
  .col-md-offset-5 {
    margin-left: 41.66667%; }
  .col-md-offset-6 {
    margin-left: 50%; }
  .col-md-offset-7 {
    margin-left: 58.33333%; }
  .col-md-offset-8 {
    margin-left: 66.66667%; }
  .col-md-offset-9 {
    margin-left: 75%; }
  .col-md-offset-10 {
    margin-left: 83.33333%; }
  .col-md-offset-11 {
    margin-left: 91.66667%; }
  .col-md-offset-12 {
    margin-left: 100%; } }

@media (min-width: 1368px) {
  .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12 {
    float: left; }
  .col-lg-1 {
    width: 8.33333%; }
  .col-lg-2 {
    width: 16.66667%; }
  .col-lg-3 {
    width: 25%; }
  .col-lg-4 {
    width: 33.33333%; }
  .col-lg-5 {
    width: 41.66667%; }
  .col-lg-6 {
    width: 50%; }
  .col-lg-7 {
    width: 58.33333%; }
  .col-lg-8 {
    width: 66.66667%; }
  .col-lg-9 {
    width: 75%; }
  .col-lg-10 {
    width: 83.33333%; }
  .col-lg-11 {
    width: 91.66667%; }
  .col-lg-12 {
    width: 100%; }
  .col-lg-pull-0 {
    right: auto; }
  .col-lg-pull-1 {
    right: 8.33333%; }
  .col-lg-pull-2 {
    right: 16.66667%; }
  .col-lg-pull-3 {
    right: 25%; }
  .col-lg-pull-4 {
    right: 33.33333%; }
  .col-lg-pull-5 {
    right: 41.66667%; }
  .col-lg-pull-6 {
    right: 50%; }
  .col-lg-pull-7 {
    right: 58.33333%; }
  .col-lg-pull-8 {
    right: 66.66667%; }
  .col-lg-pull-9 {
    right: 75%; }
  .col-lg-pull-10 {
    right: 83.33333%; }
  .col-lg-pull-11 {
    right: 91.66667%; }
  .col-lg-pull-12 {
    right: 100%; }
  .col-lg-push-0 {
    left: auto; }
  .col-lg-push-1 {
    left: 8.33333%; }
  .col-lg-push-2 {
    left: 16.66667%; }
  .col-lg-push-3 {
    left: 25%; }
  .col-lg-push-4 {
    left: 33.33333%; }
  .col-lg-push-5 {
    left: 41.66667%; }
  .col-lg-push-6 {
    left: 50%; }
  .col-lg-push-7 {
    left: 58.33333%; }
  .col-lg-push-8 {
    left: 66.66667%; }
  .col-lg-push-9 {
    left: 75%; }
  .col-lg-push-10 {
    left: 83.33333%; }
  .col-lg-push-11 {
    left: 91.66667%; }
  .col-lg-push-12 {
    left: 100%; }
  .col-lg-offset-0 {
    margin-left: 0%; }
  .col-lg-offset-1 {
    margin-left: 8.33333%; }
  .col-lg-offset-2 {
    margin-left: 16.66667%; }
  .col-lg-offset-3 {
    margin-left: 25%; }
  .col-lg-offset-4 {
    margin-left: 33.33333%; }
  .col-lg-offset-5 {
    margin-left: 41.66667%; }
  .col-lg-offset-6 {
    margin-left: 50%; }
  .col-lg-offset-7 {
    margin-left: 58.33333%; }
  .col-lg-offset-8 {
    margin-left: 66.66667%; }
  .col-lg-offset-9 {
    margin-left: 75%; }
  .col-lg-offset-10 {
    margin-left: 83.33333%; }
  .col-lg-offset-11 {
    margin-left: 91.66667%; }
  .col-lg-offset-12 {
    margin-left: 100%; } }

@media (min-width: 1230px) {
  .col-container-1, .col-container-2, .col-container-3, .col-container-4, .col-container-5, .col-container-6, .col-container-7, .col-container-8, .col-container-9, .col-container-10, .col-container-11, .col-container-12 {
    float: left; }
  .col-container-1 {
    width: 8.33333%; }
  .col-container-2 {
    width: 16.66667%; }
  .col-container-3 {
    width: 25%; }
  .col-container-4 {
    width: 33.33333%; }
  .col-container-5 {
    width: 41.66667%; }
  .col-container-6 {
    width: 50%; }
  .col-container-7 {
    width: 58.33333%; }
  .col-container-8 {
    width: 66.66667%; }
  .col-container-9 {
    width: 75%; }
  .col-container-10 {
    width: 83.33333%; }
  .col-container-11 {
    width: 91.66667%; }
  .col-container-12 {
    width: 100%; }
  .col-container-pull-0 {
    right: auto; }
  .col-container-pull-1 {
    right: 8.33333%; }
  .col-container-pull-2 {
    right: 16.66667%; }
  .col-container-pull-3 {
    right: 25%; }
  .col-container-pull-4 {
    right: 33.33333%; }
  .col-container-pull-5 {
    right: 41.66667%; }
  .col-container-pull-6 {
    right: 50%; }
  .col-container-pull-7 {
    right: 58.33333%; }
  .col-container-pull-8 {
    right: 66.66667%; }
  .col-container-pull-9 {
    right: 75%; }
  .col-container-pull-10 {
    right: 83.33333%; }
  .col-container-pull-11 {
    right: 91.66667%; }
  .col-container-pull-12 {
    right: 100%; }
  .col-container-push-0 {
    left: auto; }
  .col-container-push-1 {
    left: 8.33333%; }
  .col-container-push-2 {
    left: 16.66667%; }
  .col-container-push-3 {
    left: 25%; }
  .col-container-push-4 {
    left: 33.33333%; }
  .col-container-push-5 {
    left: 41.66667%; }
  .col-container-push-6 {
    left: 50%; }
  .col-container-push-7 {
    left: 58.33333%; }
  .col-container-push-8 {
    left: 66.66667%; }
  .col-container-push-9 {
    left: 75%; }
  .col-container-push-10 {
    left: 83.33333%; }
  .col-container-push-11 {
    left: 91.66667%; }
  .col-container-push-12 {
    left: 100%; }
  .col-container-offset-0 {
    margin-left: 0%; }
  .col-container-offset-1 {
    margin-left: 8.33333%; }
  .col-container-offset-2 {
    margin-left: 16.66667%; }
  .col-container-offset-3 {
    margin-left: 25%; }
  .col-container-offset-4 {
    margin-left: 33.33333%; }
  .col-container-offset-5 {
    margin-left: 41.66667%; }
  .col-container-offset-6 {
    margin-left: 50%; }
  .col-container-offset-7 {
    margin-left: 58.33333%; }
  .col-container-offset-8 {
    margin-left: 66.66667%; }
  .col-container-offset-9 {
    margin-left: 75%; }
  .col-container-offset-10 {
    margin-left: 83.33333%; }
  .col-container-offset-11 {
    margin-left: 91.66667%; }
  .col-container-offset-12 {
    margin-left: 100%; } }

/********************************************************************************************/
/* Utitilites-Grid--------------------------------------------------------------------------*/
/* адаптивна сетка                                                                          */
/* Основная сетка строится на flexbox'ах  + фолбэки на inline-block'ах                      */
/* Фолбэки цепляются по классу no-flexbox, который добавляет modernizer                     */
/********************************************************************************************/
/*==========================================================================================*/
/*  Основа  */
/*==========================================================================================*/
.grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin-left: -15px;
  margin-right: -15px; }
  .grid [class*="col-"] {
    margin-left: 15px;
    margin-right: 15px;
    margin-bottom: 20px; }
  .grid .row {
    display: flex;
    width: 100%; }

/*==========================================================================================*/
/*  Генерируем классы для инлайнового варианта  */
/*==========================================================================================*/
/* геренирует сетку для разрешения $screen-prefix */
/* перебирает все разрешения $grid-screen и запускает для них  @mixin grid-sizer*/
/* геренируем инлайновую сетку */
.col-1-1 {
  width: calc(100% - 30px); }

.col-offset-1-1 {
  margin-left: calc(100% + 15px); }

.col-1-2 {
  width: calc(50% - 30px); }

.col-offset-1-2 {
  margin-left: calc(50% + 15px); }

.col-1-3 {
  width: calc(33.33333% - 30px); }

.col-offset-1-3 {
  margin-left: calc(33.33333% + 15px); }

.col-2-3 {
  width: calc(66.66667% - 30px); }

.col-offset-2-3 {
  margin-left: calc(66.66667% + 15px); }

.col-1-4 {
  width: calc(25% - 30px); }

.col-offset-1-4 {
  margin-left: calc(25% + 15px); }

.col-3-4 {
  width: calc(75% - 30px); }

.col-offset-3-4 {
  margin-left: calc(75% + 15px); }

.col-1-5 {
  width: calc(20% - 30px); }

.col-offset-1-5 {
  margin-left: calc(20% + 15px); }

.col-2-5 {
  width: calc(40% - 30px); }

.col-offset-2-5 {
  margin-left: calc(40% + 15px); }

.col-3-5 {
  width: calc(60% - 30px); }

.col-offset-3-5 {
  margin-left: calc(60% + 15px); }

.col-4-5 {
  width: calc(80% - 30px); }

.col-offset-4-5 {
  margin-left: calc(80% + 15px); }

.col-1-6 {
  width: calc(16.66667% - 30px); }

.col-offset-1-6 {
  margin-left: calc(16.66667% + 15px); }

.col-5-6 {
  width: calc(83.33333% - 30px); }

.col-offset-5-6 {
  margin-left: calc(83.33333% + 15px); }

@media (max-width: 1280px) {
  .col-xl-1-1 {
    width: calc(100% - 30px); }
  .col-xl-offset-1-1 {
    margin-left: calc(100% + 15px); }
  .col-xl-1-2 {
    width: calc(50% - 30px); }
  .col-xl-offset-1-2 {
    margin-left: calc(50% + 15px); }
  .col-xl-1-3 {
    width: calc(33.33333% - 30px); }
  .col-xl-offset-1-3 {
    margin-left: calc(33.33333% + 15px); }
  .col-xl-2-3 {
    width: calc(66.66667% - 30px); }
  .col-xl-offset-2-3 {
    margin-left: calc(66.66667% + 15px); }
  .col-xl-1-4 {
    width: calc(25% - 30px); }
  .col-xl-offset-1-4 {
    margin-left: calc(25% + 15px); }
  .col-xl-3-4 {
    width: calc(75% - 30px); }
  .col-xl-offset-3-4 {
    margin-left: calc(75% + 15px); }
  .col-xl-1-5 {
    width: calc(20% - 30px); }
  .col-xl-offset-1-5 {
    margin-left: calc(20% + 15px); }
  .col-xl-2-5 {
    width: calc(40% - 30px); }
  .col-xl-offset-2-5 {
    margin-left: calc(40% + 15px); }
  .col-xl-3-5 {
    width: calc(60% - 30px); }
  .col-xl-offset-3-5 {
    margin-left: calc(60% + 15px); }
  .col-xl-4-5 {
    width: calc(80% - 30px); }
  .col-xl-offset-4-5 {
    margin-left: calc(80% + 15px); }
  .col-xl-1-6 {
    width: calc(16.66667% - 30px); }
  .col-xl-offset-1-6 {
    margin-left: calc(16.66667% + 15px); }
  .col-xl-5-6 {
    width: calc(83.33333% - 30px); }
  .col-xl-offset-5-6 {
    margin-left: calc(83.33333% + 15px); } }

@media (max-width: 1024px) {
  .col-lg-1-1 {
    width: calc(100% - 30px); }
  .col-lg-offset-1-1 {
    margin-left: calc(100% + 15px); }
  .col-lg-1-2 {
    width: calc(50% - 30px); }
  .col-lg-offset-1-2 {
    margin-left: calc(50% + 15px); }
  .col-lg-1-3 {
    width: calc(33.33333% - 30px); }
  .col-lg-offset-1-3 {
    margin-left: calc(33.33333% + 15px); }
  .col-lg-2-3 {
    width: calc(66.66667% - 30px); }
  .col-lg-offset-2-3 {
    margin-left: calc(66.66667% + 15px); }
  .col-lg-1-4 {
    width: calc(25% - 30px); }
  .col-lg-offset-1-4 {
    margin-left: calc(25% + 15px); }
  .col-lg-3-4 {
    width: calc(75% - 30px); }
  .col-lg-offset-3-4 {
    margin-left: calc(75% + 15px); }
  .col-lg-1-5 {
    width: calc(20% - 30px); }
  .col-lg-offset-1-5 {
    margin-left: calc(20% + 15px); }
  .col-lg-2-5 {
    width: calc(40% - 30px); }
  .col-lg-offset-2-5 {
    margin-left: calc(40% + 15px); }
  .col-lg-3-5 {
    width: calc(60% - 30px); }
  .col-lg-offset-3-5 {
    margin-left: calc(60% + 15px); }
  .col-lg-4-5 {
    width: calc(80% - 30px); }
  .col-lg-offset-4-5 {
    margin-left: calc(80% + 15px); }
  .col-lg-1-6 {
    width: calc(16.66667% - 30px); }
  .col-lg-offset-1-6 {
    margin-left: calc(16.66667% + 15px); }
  .col-lg-5-6 {
    width: calc(83.33333% - 30px); }
  .col-lg-offset-5-6 {
    margin-left: calc(83.33333% + 15px); } }

@media (max-width: 768px) {
  .col-md-1-1 {
    width: calc(100% - 30px); }
  .col-md-offset-1-1 {
    margin-left: calc(100% + 15px); }
  .col-md-1-2 {
    width: calc(50% - 30px); }
  .col-md-offset-1-2 {
    margin-left: calc(50% + 15px); }
  .col-md-1-3 {
    width: calc(33.33333% - 30px); }
  .col-md-offset-1-3 {
    margin-left: calc(33.33333% + 15px); }
  .col-md-2-3 {
    width: calc(66.66667% - 30px); }
  .col-md-offset-2-3 {
    margin-left: calc(66.66667% + 15px); }
  .col-md-1-4 {
    width: calc(25% - 30px); }
  .col-md-offset-1-4 {
    margin-left: calc(25% + 15px); }
  .col-md-3-4 {
    width: calc(75% - 30px); }
  .col-md-offset-3-4 {
    margin-left: calc(75% + 15px); }
  .col-md-1-5 {
    width: calc(20% - 30px); }
  .col-md-offset-1-5 {
    margin-left: calc(20% + 15px); }
  .col-md-2-5 {
    width: calc(40% - 30px); }
  .col-md-offset-2-5 {
    margin-left: calc(40% + 15px); }
  .col-md-3-5 {
    width: calc(60% - 30px); }
  .col-md-offset-3-5 {
    margin-left: calc(60% + 15px); }
  .col-md-4-5 {
    width: calc(80% - 30px); }
  .col-md-offset-4-5 {
    margin-left: calc(80% + 15px); }
  .col-md-1-6 {
    width: calc(16.66667% - 30px); }
  .col-md-offset-1-6 {
    margin-left: calc(16.66667% + 15px); }
  .col-md-5-6 {
    width: calc(83.33333% - 30px); }
  .col-md-offset-5-6 {
    margin-left: calc(83.33333% + 15px); } }

@media (max-width: 420px) {
  .col-sm-1-1 {
    width: calc(100% - 30px); }
  .col-sm-offset-1-1 {
    margin-left: calc(100% + 15px); }
  .col-sm-1-2 {
    width: calc(50% - 30px); }
  .col-sm-offset-1-2 {
    margin-left: calc(50% + 15px); }
  .col-sm-1-3 {
    width: calc(33.33333% - 30px); }
  .col-sm-offset-1-3 {
    margin-left: calc(33.33333% + 15px); }
  .col-sm-2-3 {
    width: calc(66.66667% - 30px); }
  .col-sm-offset-2-3 {
    margin-left: calc(66.66667% + 15px); }
  .col-sm-1-4 {
    width: calc(25% - 30px); }
  .col-sm-offset-1-4 {
    margin-left: calc(25% + 15px); }
  .col-sm-3-4 {
    width: calc(75% - 30px); }
  .col-sm-offset-3-4 {
    margin-left: calc(75% + 15px); }
  .col-sm-1-5 {
    width: calc(20% - 30px); }
  .col-sm-offset-1-5 {
    margin-left: calc(20% + 15px); }
  .col-sm-2-5 {
    width: calc(40% - 30px); }
  .col-sm-offset-2-5 {
    margin-left: calc(40% + 15px); }
  .col-sm-3-5 {
    width: calc(60% - 30px); }
  .col-sm-offset-3-5 {
    margin-left: calc(60% + 15px); }
  .col-sm-4-5 {
    width: calc(80% - 30px); }
  .col-sm-offset-4-5 {
    margin-left: calc(80% + 15px); }
  .col-sm-1-6 {
    width: calc(16.66667% - 30px); }
  .col-sm-offset-1-6 {
    margin-left: calc(16.66667% + 15px); }
  .col-sm-5-6 {
    width: calc(83.33333% - 30px); }
  .col-sm-offset-5-6 {
    margin-left: calc(83.33333% + 15px); } }

@media (max-width: 320px) {
  .col-xs-1-1 {
    width: calc(100% - 30px); }
  .col-xs-offset-1-1 {
    margin-left: calc(100% + 15px); }
  .col-xs-1-2 {
    width: calc(50% - 30px); }
  .col-xs-offset-1-2 {
    margin-left: calc(50% + 15px); }
  .col-xs-1-3 {
    width: calc(33.33333% - 30px); }
  .col-xs-offset-1-3 {
    margin-left: calc(33.33333% + 15px); }
  .col-xs-2-3 {
    width: calc(66.66667% - 30px); }
  .col-xs-offset-2-3 {
    margin-left: calc(66.66667% + 15px); }
  .col-xs-1-4 {
    width: calc(25% - 30px); }
  .col-xs-offset-1-4 {
    margin-left: calc(25% + 15px); }
  .col-xs-3-4 {
    width: calc(75% - 30px); }
  .col-xs-offset-3-4 {
    margin-left: calc(75% + 15px); }
  .col-xs-1-5 {
    width: calc(20% - 30px); }
  .col-xs-offset-1-5 {
    margin-left: calc(20% + 15px); }
  .col-xs-2-5 {
    width: calc(40% - 30px); }
  .col-xs-offset-2-5 {
    margin-left: calc(40% + 15px); }
  .col-xs-3-5 {
    width: calc(60% - 30px); }
  .col-xs-offset-3-5 {
    margin-left: calc(60% + 15px); }
  .col-xs-4-5 {
    width: calc(80% - 30px); }
  .col-xs-offset-4-5 {
    margin-left: calc(80% + 15px); }
  .col-xs-1-6 {
    width: calc(16.66667% - 30px); }
  .col-xs-offset-1-6 {
    margin-left: calc(16.66667% + 15px); }
  .col-xs-5-6 {
    width: calc(83.33333% - 30px); }
  .col-xs-offset-5-6 {
    margin-left: calc(83.33333% + 15px); } }

.clear:after {
  content: '';
  display: block;
  clear: both; }

.pull-right {
  float: right; }

.pull-left {
  float: left; }

.no-margin {
  margin: 0; }

.rounded {
  border-radius: 50%; }

.noborder {
  border: none; }

.justify {
  width: 100%; }

.text-center {
  text-align: center; }

.font-inherit {
  font-size: inherit;
  text-align: inherit;
  line-height: inherit;
  text-decoration: inherit;
  text-transform: inherit;
  color: inherit;
  font-family: inherit;
  text-indent: inherit;
  word-break: inherit;
  white-space: inherit; }

.font-light {
  font-weight: 300; }

.font-regular {
  font-weight: 400; }

.font-semibold {
  font-weight: 600; }

.font-bold {
  font-weight: 700; }

@media only screen and (max-width: 400px -1) {
  .hide-xs {
    display: none !important; } }

@media only screen and (min-width: 401px) {
  .show-xs {
    display: none !important; } }

@media only screen and (max-width: 767px) {
  .hide-sm {
    display: none !important; } }

@media only screen and (min-width: 769px) {
  .show-sm {
    display: none !important; } }

@media only screen and (max-width: 1024px) {
  .hide-md {
    display: none !important; } }

@media only screen and (min-width: 1025px) {
  .show-md {
    display: none !important; } }

@media only screen and (max-width: 1368px) {
  .hide-lg {
    display: none !important; } }

@media only screen and (min-width: 1369px) {
  .show-lg {
    display: none !important; } }

.icon {
  display: inline-block;
  line-height: 1;
  font-size: 1px; }

.icon-svg {
  fill: none;
  stroke: #000;
  width: 1em;
  height: 1em; }

.icon-svg-star {
  font-size: 3.2rem;
  width: 1em; }

.icon-svg-star-o {
  font-size: 3.2rem;
  width: 0.5em; }

.bulet-active {
  background-image: url(../images/icons.png);
  background-position: -127px -104px;
  width: 12px;
  height: 12px; }

.bulet {
  background-image: url(../images/icons.png);
  background-position: -127px -90px;
  width: 12px;
  height: 12px; }

.calendar-white {
  background-image: url(../images/icons.png);
  background-position: -79px 0px;
  width: 24px;
  height: 24px; }

.calendar {
  background-image: url(../images/icons.png);
  background-position: -52px -27px;
  width: 24px;
  height: 24px; }

.call-white {
  background-image: url(../images/icons.png);
  background-position: -59px -104px;
  width: 15px;
  height: 15px; }

.call {
  background-image: url(../images/icons.png);
  background-position: -42px -104px;
  width: 15px;
  height: 15px; }

.contact-adress {
  background-image: url(../images/icons.png);
  background-position: -52px 0px;
  width: 25px;
  height: 25px; }

.contact-email {
  background-image: url(../images/icons.png);
  background-position: -25px -56px;
  width: 24px;
  height: 19px; }

.contact-fax {
  background-image: url(../images/icons.png);
  background-position: -105px -18px;
  width: 20px;
  height: 16px; }

.contact-houre {
  background-image: url(../images/icons.png);
  background-position: -79px -26px;
  width: 23px;
  height: 23px; }

.contact-phone {
  background-image: url(../images/icons.png);
  background-position: 0px -56px;
  width: 23px;
  height: 23px; }

.contact-print {
  background-image: url(../images/icons.png);
  background-position: -74px -81px;
  width: 20px;
  height: 16px; }

.copntact-email {
  background-image: url(../images/icons.png);
  background-position: -51px -56px;
  width: 24px;
  height: 19px; }

.eyes {
  background-image: url(../images/icons.png);
  background-position: -76px -104px;
  width: 16px;
  height: 12px; }

.fb-footer {
  background-image: url(../images/icons.png);
  background-position: -127px -68px;
  width: 11px;
  height: 20px; }

.fb-header {
  background-image: url(../images/icons.png);
  background-position: -127px -46px;
  width: 11px;
  height: 20px; }

.gplus-footer {
  background-image: url(../images/icons.png);
  background-position: -77px -56px;
  width: 26px;
  height: 16px; }

.gplus-header {
  background-image: url(../images/icons.png);
  background-position: -46px -81px;
  width: 26px;
  height: 16px; }

.inst-footer {
  background-image: url(../images/icons.png);
  background-position: -23px -81px;
  width: 21px;
  height: 21px; }

.inst-header {
  background-image: url(../images/icons.png);
  background-position: 0px -81px;
  width: 21px;
  height: 21px; }

.mail-white {
  background-image: url(../images/icons.png);
  background-position: -105px -54px;
  width: 20px;
  height: 16px; }

.mail {
  background-image: url(../images/icons.png);
  background-position: -105px -72px;
  width: 20px;
  height: 16px; }

.phone-arrow {
  background-image: url(../images/icons.png);
  background-position: -24px -122px;
  width: 10px;
  height: 8px; }

.read-more-dots-hover {
  background-image: url(../images/icons.png);
  background-position: -46px -99px;
  width: 15px;
  height: 3px; }

.read-more-dots {
  background-image: url(../images/icons.png);
  background-position: -79px -51px;
  width: 15px;
  height: 3px; }

.show-all-arrow-hover {
  background-image: url(../images/icons.png);
  background-position: -110px -104px;
  width: 14px;
  height: 10px; }

.show-all-arrow {
  background-image: url(../images/icons.png);
  background-position: -94px -104px;
  width: 14px;
  height: 10px; }

.slider-arrow-hover {
  background-image: url(../images/icons.png);
  background-position: -127px 0px;
  width: 12px;
  height: 21px; }

.slider-arrow {
  background-image: url(../images/icons.png);
  background-position: -127px -23px;
  width: 12px;
  height: 21px; }

.star-white {
  background-image: url(../images/icons.png);
  background-position: -25px 0px;
  width: 25px;
  height: 25px; }

.star {
  background-image: url(../images/icons.png);
  background-position: -25px -27px;
  width: 25px;
  height: 25px; }

.to-top {
  background-image: url(../images/icons.png);
  background-position: 0px 0px;
  width: 23px;
  height: 54px; }

.top-menu-arrow-hover {
  background-image: url(../images/icons.png);
  background-position: 0px -122px;
  width: 10px;
  height: 8px; }

.top-menu-arrow {
  background-image: url(../images/icons.png);
  background-position: -12px -122px;
  width: 10px;
  height: 8px; }

.tw-footer {
  background-image: url(../images/icons.png);
  background-position: -21px -104px;
  width: 19px;
  height: 16px; }

.tw-header {
  background-image: url(../images/icons.png);
  background-position: 0px -104px;
  width: 19px;
  height: 16px; }

.u-footer {
  background-image: url(../images/icons.png);
  background-position: -105px 0px;
  width: 20px;
  height: 16px; }

.u-header {
  background-image: url(../images/icons.png);
  background-position: -105px -36px;
  width: 20px;
  height: 16px; }

.year {
  background-image: url(../images/icons.png);
  background-position: -105px -90px;
  width: 16px;
  height: 12px; }

@media only screen and (max-width: 950px) {
  .main-header .container {
    width: 100%;
    padding-left: 0;
    padding-right: 0; } }

.main-header .header-top {
  padding-top: 18px;
  padding-bottom: 10px; }
  .main-header .header-top:after {
    content: '';
    display: block;
    clear: both; }
  .main-header .header-top .header-top-logo {
    float: left;
    padding-left: 25px; }
    .main-header .header-top .header-top-logo img {
      display: block; }
  .main-header .header-top .header-top-phone {
    float: right;
    margin-right: 140px; }
    .main-header .header-top .header-top-phone a {
      font-style: italic;
      font-weight: 600;
      font-size: 31px;
      color: #c9ab6d;
      border-bottom: none; }
      .main-header .header-top .header-top-phone a img {
        display: block; }
    @media only screen and (max-width: 1000px) {
      .main-header .header-top .header-top-phone {
        text-align: center;
        width: 32%;
        margin-right: 0; } }
  @media only screen and (max-width: 950px) {
    .main-header .header-top {
      display: none; } }

.main-header .header-middle .header-gamburger {
  display: none; }
  @media only screen and (max-width: 1000px) {
    .main-header .header-middle .header-gamburger {
      display: inline-block;
      position: relative;
      width: 40px;
      height: 30px;
      margin-top: 16px;
      margin-left: 30px; }
      .main-header .header-middle .header-gamburger span {
        background-color: #ffffff;
        position: absolute;
        width: 100%;
        height: 6px;
        border-radius: 2px; }
        .main-header .header-middle .header-gamburger span:nth-of-type(1) {
          top: 0; }
        .main-header .header-middle .header-gamburger span:nth-of-type(2) {
          top: 50%;
          margin-top: -3px; }
        .main-header .header-middle .header-gamburger span:nth-of-type(3) {
          bottom: 0; } }

.main-header .header-middle .mobile-menu {
  display: none; }
  @media only screen and (max-width: 1000px) {
    .main-header .header-middle .mobile-menu {
      position: absolute;
      left: 0;
      top: 100%;
      width: 200px;
      background: #c58200;
      padding: 20px; }
      .main-header .header-middle .mobile-menu ul {
        float: none;
        width: 100%; }
        .main-header .header-middle .mobile-menu ul li {
          width: 100%;
          height: auto;
          text-align: left;
          padding: 7px 0; }
          .main-header .header-middle .mobile-menu ul li:before, .main-header .header-middle .mobile-menu ul li:after {
            display: none; }
        .main-header .header-middle .mobile-menu ul:last-of-type li {
          width: 100%;
          height: auto; } }
  @media only screen and (max-width: 500px) {
    .main-header .header-middle .mobile-menu {
      width: 100%;
      right: 0;
      z-index: 1;
      top: 100px; } }
  @media only screen and (max-width: 450px) {
    .main-header .header-middle .mobile-menu {
      top: 87px; } }
  @media only screen and (max-width: 400px) {
    .main-header .header-middle .mobile-menu {
      top: 76px; } }

.main-header .header-middle .header-logo {
  position: absolute;
  top: -59px;
  left: 50%;
  transform: translateX(-50%);
  border-bottom: none;
  display: block; }
  @media only screen and (max-width: 600px) {
    .main-header .header-middle .header-logo {
      width: 300px; } }
  @media only screen and (max-width: 500px) {
    .main-header .header-middle .header-logo {
      width: 250px;
      top: -43px; } }
  @media only screen and (max-width: 450px) {
    .main-header .header-middle .header-logo {
      width: 200px;
      top: -28px; } }
  @media only screen and (max-width: 400px) {
    .main-header .header-middle .header-logo {
      width: 170px;
      top: -21px; } }

.main-header .header-middle .header-form {
  float: right; }

@media only screen and (max-width: 950px) {
  .main-header .header-middle {
    margin-top: 55px; } }

@media only screen and (max-width: 500px) {
  .main-header .header-middle {
    margin-top: 42px; } }

@media only screen and (max-width: 450px) {
  .main-header .header-middle {
    margin-top: 27px; } }

@media only screen and (max-width: 400px) {
  .main-header .header-middle {
    margin-top: 20px; } }

.main-header .header-bottom {
  padding-top: 10px;
  padding-bottom: 37px; }
  .main-header .header-bottom:after {
    content: '';
    display: block;
    clear: both; }
  .main-header .header-bottom span {
    font-size: 15px;
    color: #ffffff;
    float: left;
    width: 38%;
    text-align: center;
    font-style: italic; }
    @media only screen and (max-width: 950px) {
      .main-header .header-bottom span {
        display: none; } }
  .main-header .header-bottom a {
    float: right;
    font-size: 15px;
    color: #ffffff;
    border-bottom: none;
    width: 46%;
    text-align: center;
    font-style: italic; }
  .main-header .header-bottom p {
    text-align: center;
    font-size: 15px;
    color: #ccc4c3;
    max-width: 510px;
    margin-left: auto;
    margin-right: auto;
    padding-top: 50px;
    line-height: 17px; }
    .main-header .header-bottom p img {
      display: block;
      margin-left: auto;
      margin-right: auto; }
    @media only screen and (max-width: 950px) {
      .main-header .header-bottom p {
        padding-top: 35px;
        margin-bottom: 0;
        max-width: 90%; }
        .main-header .header-bottom p img {
          margin-bottom: 10px; } }
  @media only screen and (max-width: 950px) {
    .main-header .header-bottom .header-bottom-mail {
      display: none; } }
  .main-header .header-bottom .header-bottom-mobile {
    display: none; }
    @media only screen and (max-width: 950px) {
      .main-header .header-bottom .header-bottom-mobile {
        display: block;
        text-align: center;
        margin-top: 60px;
        width: 310px;
        margin-left: auto;
        margin-right: auto; }
        .main-header .header-bottom .header-bottom-mobile img {
          display: block;
          margin-left: auto;
          margin-right: auto; }
        .main-header .header-bottom .header-bottom-mobile .header-phone-mobile {
          display: inline-block;
          float: none;
          width: auto;
          margin-top: 5px; }
        .main-header .header-bottom .header-bottom-mobile .header-text-mobile {
          display: block;
          float: none;
          width: auto;
          margin-top: 12px; }
        .main-header .header-bottom .header-bottom-mobile .header-mail-mobile {
          float: none;
          width: auto;
          margin-top: 8px;
          display: inline-block; } }
    @media only screen and (max-width: 450px) {
      .main-header .header-bottom .header-bottom-mobile {
        margin-top: 30px; } }

.index-slider-wrapper {
  width: 100%;
  overflow: hidden;
  position: relative; }
  @media only screen and (max-width: 950px) {
    .index-slider-wrapper .container {
      width: 100%;
      padding-left: 0;
      padding-right: 0; } }

.index-slider {
  width: 140%;
  margin-left: -20%; }
  @media only screen and (max-width: 500px) {
    .index-slider {
      width: 100%;
      margin-left: 0; } }
  .index-slider .owl-stage-outer {
    overflow: visible !important; }
  .index-slider .index-slide-item {
    overflow: hidden; }
    .index-slider .index-slide-item img {
      filter: grayscale(1) blur(5px); }
  .index-slider .owl-item.active .index-slide-item img {
    filter: none; }
  .index-slider .slick-center img {
    filter: none; }
  .index-slider .owl-nav .owl-prev,
  .index-slider .owl-nav .owl-next {
    position: absolute;
    top: 50%;
    right: -30px;
    margin-top: -30px;
    width: 60px;
    height: 60px;
    background: url("../images/slider-arrow.png") no-repeat center center, #482418;
    border-radius: 50%; }
    @media only screen and (max-width: 600px) {
      .index-slider .owl-nav .owl-prev,
      .index-slider .owl-nav .owl-next {
        right: 0; } }
  .index-slider .owl-nav .owl-prev {
    right: auto;
    left: -30px;
    transform: rotate(180deg); }
    @media only screen and (max-width: 600px) {
      .index-slider .owl-nav .owl-prev {
        left: 0; } }

.index-slider-nav {
  width: 28%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); }
  @media only screen and (max-width: 1200px) {
    .index-slider-nav {
      width: 47%; } }
  @media only screen and (max-width: 500px) {
    .index-slider-nav {
      width: 98%; } }
  .index-slider-nav .index-slider-nav-prev,
  .index-slider-nav .index-slider-nav-next {
    position: absolute;
    top: 50%;
    right: -30px;
    margin-top: -30px;
    width: 60px;
    height: 60px;
    background: url("../images/slider-arrow.png") no-repeat center center, #482418;
    border-radius: 50%;
    cursor: pointer; }
    @media only screen and (max-width: 500px) {
      .index-slider-nav .index-slider-nav-prev,
      .index-slider-nav .index-slider-nav-next {
        right: 0; } }
  .index-slider-nav .index-slider-nav-prev {
    right: auto;
    left: -30px;
    transform: rotate(180deg); }
    @media only screen and (max-width: 500px) {
      .index-slider-nav .index-slider-nav-prev {
        left: 0; } }

.index-slider-controls {
  width: 445px;
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 35px; }
  @media only screen and (max-width: 489px) {
    .index-slider-controls {
      width: 100%; } }
  .index-slider-controls .owl-item {
    cursor: pointer; }
    .index-slider-controls .owl-item img {
      filter: grayscale(1); }
    .index-slider-controls .owl-item.current img {
      filter: none; }
  .index-slider-controls .index-slide-control-item img {
    cursor: pointer;
    filter: grayscale(1); }
  .index-slider-controls .slick-center img {
    filter: none; }

.index-advantage {
  margin-top: 40px;
  background-color: #fff3db;
  position: relative; }
  .index-advantage:before {
    content: '';
    display: block;
    width: 13px;
    height: 100%;
    background-color: #fff3db;
    position: absolute;
    top: 0;
    left: -13px; }
  .index-advantage:after {
    content: '';
    display: block;
    width: 13px;
    height: 100%;
    background-color: #fff3db;
    position: absolute;
    top: 0;
    right: -13px; }
  @media only screen and (max-width: 950px) {
    .index-advantage:before, .index-advantage:after {
      display: none; } }
  @media only screen and (max-width: 500px) {
    .index-advantage {
      margin-top: 30px; } }
  @media only screen and (max-width: 400px) {
    .index-advantage {
      margin-top: 22px; } }
  .index-advantage .index-advantage-tit img {
    display: block;
    margin-left: auto;
    margin-right: auto;
    position: relative;
    top: -40px; }
  @media only screen and (max-width: 500px) {
    .index-advantage .index-advantage-tit img {
      top: -30px; } }
  @media only screen and (max-width: 400px) {
    .index-advantage .index-advantage-tit img {
      top: -20px; } }
  .index-advantage .index-advantage-list {
    display: flex;
    flex-wrap: wrap;
    margin-top: -20px; }
    .index-advantage .index-advantage-list:before {
      content: '';
      width: 13px;
      height: 13px;
      display: block;
      position: absolute;
      left: -13px;
      bottom: -13px;
      border: 6px solid #5b3b11;
      border-left-color: transparent;
      border-bottom-color: transparent;
      box-sizing: border-box; }
    .index-advantage .index-advantage-list:after {
      content: '';
      width: 13px;
      height: 13px;
      display: block;
      position: absolute;
      right: -13px;
      bottom: -13px;
      border: 6px solid #5b3b11;
      border-right-color: transparent;
      border-bottom-color: transparent;
      box-sizing: border-box; }
    @media only screen and (max-width: 950px) {
      .index-advantage .index-advantage-list:before, .index-advantage .index-advantage-list:after {
        display: none; } }
    .index-advantage .index-advantage-list .index-advantage-item {
      width: 25%;
      padding: 0 5px;
      text-align: center; }
      .index-advantage .index-advantage-list .index-advantage-item img {
        margin-bottom: 20px; }
      .index-advantage .index-advantage-list .index-advantage-item p {
        color: #000000;
        font-weight: 400;
        text-align: center; }
      @media only screen and (max-width: 750px) {
        .index-advantage .index-advantage-list .index-advantage-item {
          width: 50%;
          padding: 0 10px; } }
      @media only screen and (max-width: 500px) {
        .index-advantage .index-advantage-list .index-advantage-item {
          width: 100%; } }

.index-services {
  padding-bottom: 55px; }
  .index-services .index-services-tit {
    text-align: center;
    margin-top: 35px; }
  .index-services .index-services-list {
    text-align: center;
    padding-top: 20px; }
    .index-services .index-services-list .index-services-item {
      display: inline-block;
      vertical-align: top;
      padding: 0 32px; }
      .index-services .index-services-list .index-services-item img {
        display: block;
        margin-bottom: 7px;
        margin-left: auto;
        margin-right: auto; }
      .index-services .index-services-list .index-services-item a {
        text-align: center;
        display: block;
        line-height: 15px;
        border-bottom: none;
        font-size: 15px;
        color: #ffffff; }
        .index-services .index-services-list .index-services-item a:last-of-type {
          display: inline-block;
          max-width: 220px; }
      @media only screen and (max-width: 800px) {
        .index-services .index-services-list .index-services-item {
          padding-left: 10px;
          padding-right: 10px; } }
      @media only screen and (max-width: 700px) {
        .index-services .index-services-list .index-services-item {
          width: 60%;
          margin-bottom: 30px;
          text-align: center; } }
      @media only screen and (max-width: 500px) {
        .index-services .index-services-list .index-services-item {
          width: auto; } }

.main-footer {
  padding: 15px 0;
  background: url("../images/footer-bg.png") repeat left top; }
  @media only screen and (max-width: 750px) {
    .main-footer {
      padding-top: 25px; } }
  .main-footer .container {
    background: none;
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap; }
    .main-footer .container .footer-left {
      display: flex; }
      @media only screen and (max-width: 750px) {
        .main-footer .container .footer-left {
          width: 100%;
          margin-bottom: 30px;
          justify-content: center; } }
      .main-footer .container .footer-left .footer-logo {
        display: block;
        border-bottom: none;
        padding-right: 20px;
        margin-right: 23px;
        border-right: 1px solid #FFFFFF; }
        .main-footer .container .footer-left .footer-logo img {
          display: block; }
    .main-footer .container .footer-center {
      text-align: center; }
      @media only screen and (max-width: 500px) {
        .main-footer .container .footer-center {
          width: 100%;
          margin-bottom: 30px;
          justify-content: center; } }
      .main-footer .container .footer-center .footer-phone {
        font-size: 29px;
        font-weight: 500;
        color: #ffffff;
        border-bottom: none;
        font-style: italic;
        display: block;
        margin-bottom: 5px;
        text-align: center; }
      .main-footer .container .footer-center .footer-mail {
        border-bottom: none;
        color: #ffffff;
        font-style: italic;
        font-size: 15px; }
    .main-footer .container .footer-right {
      max-width: 200px;
      text-align: center; }
      .main-footer .container .footer-right span {
        color: #ffffff;
        font-size: 15px; }
      @media only screen and (max-width: 500px) {
        .main-footer .container .footer-right {
          width: 100%;
          margin-bottom: 30px;
          justify-content: center;
          margin-bottom: 10px; } }
      .main-footer .container .footer-right .footer-social-block {
        margin-top: 10px; }
        .main-footer .container .footer-right .footer-social-block a {
          border-bottom: none;
          display: inline-block;
          vertical-align: middle;
          margin-right: 15px; }
          .main-footer .container .footer-right .footer-social-block a:last-of-type {
            margin-right: 0; }

.inner-page-gallery .col-2 {
  padding: 0 5px; }
  @media only screen and (max-width: 500px) {
    .inner-page-gallery .col-2 {
      float: none;
      width: 100%;
      overflow: auto; } }

.inner-page-gallery .col-10 {
  padding: 0 5px; }
  @media only screen and (max-width: 500px) {
    .inner-page-gallery .col-10 {
      float: none;
      width: 100%; } }

@media only screen and (max-width: 500px) {
  .inner-page-gallery .inner-page-gallery-preview-wrapper {
    white-space: nowrap;
    margin-bottom: 7px; } }

.inner-page-gallery .inner-page-gallery-preview-wrapper .inner-page-gallery-preview-item {
  margin-bottom: 10px;
  position: relative;
  cursor: pointer;
  height: 81px;
  background-position: center; }
  @media only screen and (max-width: 500px) {
    .inner-page-gallery .inner-page-gallery-preview-wrapper .inner-page-gallery-preview-item {
      display: inline-block;
      vertical-align: top;
      margin-right: 5px;
      margin-bottom: 0; } }
  .inner-page-gallery .inner-page-gallery-preview-wrapper .inner-page-gallery-preview-item:after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    -webkit-transition: all linear 0.2s 0s;
    -moz-transition: all linear 0.2s 0s;
    -ms-transition: all linear 0.2s 0s;
    -o-transition: all linear 0.2s 0s;
    transition: all linear 0.2s 0s; }
  .inner-page-gallery .inner-page-gallery-preview-wrapper .inner-page-gallery-preview-item img {
    display: block;
    margin-left: auto;
    margin-right: 0;
    filter: grayscale(1);
    -webkit-transition: all linear 0.2s 0s;
    -moz-transition: all linear 0.2s 0s;
    -ms-transition: all linear 0.2s 0s;
    -o-transition: all linear 0.2s 0s;
    transition: all linear 0.2s 0s; }
  .inner-page-gallery .inner-page-gallery-preview-wrapper .inner-page-gallery-preview-item.is-active {
    cursor: auto; }
    .inner-page-gallery .inner-page-gallery-preview-wrapper .inner-page-gallery-preview-item.is-active:after {
      box-shadow: inset 0 0 0 7px #e59f2d; }
    .inner-page-gallery .inner-page-gallery-preview-wrapper .inner-page-gallery-preview-item.is-active img {
      filter: none; }

.inner-page-gallery .inner-page-gallery-big-wrapper {
  position: relative;
  height: 445px; }
  @media only screen and (max-width: 500px) {
    .inner-page-gallery .inner-page-gallery-big-wrapper {
      height: auto;
      padding-bottom: 57%; } }
  .inner-page-gallery .inner-page-gallery-big-wrapper .inner-page-gallery-big-item {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    opacity: 0;
    background-position: center;
    -webkit-transition: all linear 0.2s 0s;
    -moz-transition: all linear 0.2s 0s;
    -ms-transition: all linear 0.2s 0s;
    -o-transition: all linear 0.2s 0s;
    transition: all linear 0.2s 0s; }
    @media only screen and (max-width: 500px) {
      .inner-page-gallery .inner-page-gallery-big-wrapper .inner-page-gallery-big-item {
        background-size: 100% auto; } }
    .inner-page-gallery .inner-page-gallery-big-wrapper .inner-page-gallery-big-item img {
      display: block;
      max-width: 1000px;
      max-height: 1000px; }
    .inner-page-gallery .inner-page-gallery-big-wrapper .inner-page-gallery-big-item.is-active {
      opacity: 1; }

.inner-page-gallery .inner-page-gallery-nav .inner-page-gallery-nav-prev,
.inner-page-gallery .inner-page-gallery-nav .inner-page-gallery-nav-next {
  width: 38px;
  height: 64px;
  position: absolute;
  top: 50%;
  margin-top: -32px;
  right: 20px;
  background: url("../images/slider-next.png") no-repeat center center;
  cursor: pointer; }

.inner-page-gallery .inner-page-gallery-nav .inner-page-gallery-nav-prev {
  right: auto;
  left: 20px;
  background: url("../images/slider-prev.png") no-repeat center center; }

.inner-page-form {
  margin-bottom: 30px; }
  .inner-page-form .inner-page-form-tit {
    display: block;
    text-align: center;
    margin-bottom: 15px; }
  .inner-page-form textarea {
    height: 123px; }
  .inner-page-form [type="submit"] {
    margin-left: auto;
    margin-right: auto;
    width: 140px;
    display: block;
    margin-top: -10px; }

.inner-page-text-btn-block {
  border: 1px solid #e59f2d;
  padding: 20px;
  margin: 20px 0; }
  .inner-page-text-btn-block p {
    margin-bottom: 5px; }
  .inner-page-text-btn-block .button {
    margin-top: 4px;
    display: inline-block;
    padding-top: 12px;
    height: auto;
    text-align: center;
    padding-bottom: 12px; }

.inner-page-inline-blocks {
  display: flex;
  flex-wrap: wrap;
  margin-left: -25px;
  margin-right: -25px; }
  .inner-page-inline-blocks .inner-page-inline-block-item {
    position: relative;
    width: 20%;
    padding: 0 25px; }
    .inner-page-inline-blocks .inner-page-inline-block-item:after {
      content: '';
      display: block;
      width: 40px;
      height: 40px;
      position: absolute;
      top: 50%;
      margin-top: -20px;
      right: -20px;
      background: url("../images/right-arrow.png") no-repeat center center;
      background-size: 100%; }
    .inner-page-inline-blocks .inner-page-inline-block-item:last-of-type:after {
      display: none; }
    .inner-page-inline-blocks .inner-page-inline-block-item p {
      display: flex;
      align-items: center;
      justify-content: center;
      width: calc(100% - 40px);
      background-color: #e59f2d;
      border-radius: 5px;
      padding: 10px;
      text-align: center;
      height: 100%; }
    @media only screen and (min-width: 501px) and (max-width: 800px) {
      .inner-page-inline-blocks .inner-page-inline-block-item {
        width: 33.333%;
        text-align: center;
        margin-bottom: 15px; }
        .inner-page-inline-blocks .inner-page-inline-block-item:nth-of-type(3):after {
          display: none; } }
    @media only screen and (max-width: 500px) {
      .inner-page-inline-blocks .inner-page-inline-block-item {
        width: 50%;
        text-align: center;
        margin-bottom: 15px; }
        .inner-page-inline-blocks .inner-page-inline-block-item:nth-of-type(2):after, .inner-page-inline-blocks .inner-page-inline-block-item:nth-of-type(4):after {
          display: none; } }

.inner-page-form-btn-wrapper {
  text-align: center;
  margin: 10px 0; }
  .inner-page-form-btn-wrapper .button {
    display: inline-block;
    height: auto;
    padding: 12px 15px; }

.contact-tit {
  font-size: 18px; }

.contact-text span {
  display: block; }

.contact-map {
  height: 450px; }

.print-btn-wrapper:after {
  content: '';
  display: block;
  clear: both; }

.print-btn-wrapper p {
  display: inline-block; }
  @media only screen and (max-width: 450px) {
    .print-btn-wrapper p {
      display: block; } }

.print-btn-wrapper .print-btn {
  float: right;
  position: relative;
  margin-left: 25px; }
  .print-btn-wrapper .print-btn:before {
    content: '';
    position: absolute;
    top: 5px;
    left: -25px;
    display: block;
    width: 16px;
    height: 16px;
    background: url("../images/print.png") no-repeat center; }
  @media only screen and (max-width: 450px) {
    .print-btn-wrapper .print-btn {
      float: none;
      margin-bottom: 10px;
      display: inline-block; } }

.inner-top-menu {
  border-top: 1px solid #e59f2d;
  border-bottom: 1px solid #e59f2d;
  padding: 5px 0 0; }
  .inner-top-menu ul {
    list-style: none;
    margin: 0;
    padding: 0;
    font-size: 0;
    display: block;
    width: 100%;
    text-align: center; }
    .inner-top-menu ul li {
      position: relative;
      display: inline-block;
      vertical-align: middle;
      padding: 15px 15px;
      margin-right: 10px;
      max-width: 100%;
      margin-bottom: 5px; }
      .inner-top-menu ul li a {
        border-bottom: none;
        color: #ffffff; }
      .inner-top-menu ul li.current, .inner-top-menu ul li:hover {
        background-color: #e59f2d; }
        .inner-top-menu ul li.current:after, .inner-top-menu ul li:hover:after {
          content: '';
          position: absolute;
          top: 0;
          right: -10px;
          display: block;
          width: 10px;
          height: 100%;
          border-left: 10px solid #e59f2d;
          border-top: 26px solid transparent;
          border-bottom: 26px solid transparent;
          box-sizing: border-box; }
        .inner-top-menu ul li.current a, .inner-top-menu ul li:hover a {
          display: inline-block;
          color: #000000;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          max-width: 100%; }

.values-slider {
  margin-top: 15px;
  margin-bottom: 30px; }
  .values-slider.owl-carousel {
    padding: 0 50px; }
    @media only screen and (max-width: 500px) {
      .values-slider.owl-carousel {
        padding: 0; } }
  .values-slider .values-slider-item {
    width: 33%;
    float: left;
    padding: 0 10px; }
    .values-slider .values-slider-item .values-slider-item-img {
      height: 130px;
      text-align: center; }
      .values-slider .values-slider-item .values-slider-item-img:before {
        content: '';
        width: 0;
        height: 100%;
        display: inline-block;
        vertical-align: middle; }
    .values-slider .values-slider-item img {
      display: inline-block;
      vertical-align: middle;
      max-height: 100%;
      max-width: 100%; }
    .values-slider .values-slider-item .values-slider-item-tit {
      font-size: 20px;
      display: block;
      text-align: center;
      margin: 10px 0; }
    .values-slider .values-slider-item p {
      margin: 0; }
  .values-slider .owl-item img {
    width: auto; }
  .values-slider .owl-item .values-slider-item {
    width: 100%;
    float: none; }
  .values-slider .owl-prev,
  .values-slider .owl-next {
    width: 46px;
    height: 84px;
    position: absolute;
    top: 50%;
    right: 0;
    margin-top: -42px;
    background: url("../images/value-slider-arrow-next.png"); }
  .values-slider .owl-prev {
    left: 0;
    right: auto;
    background: url("../images/value-slider-arrow-prev.png"); }
  .values-slider .owl-dots {
    margin-top: 20px;
    text-align: center; }
    .values-slider .owl-dots .owl-dot {
      display: inline-block;
      vertical-align: middle;
      margin-right: 8px;
      width: 10px;
      height: 10px;
      border-radius: 50%;
      border: 3px solid #ffffff;
      transition: transform 0.3s, border 0.3s; }
      .values-slider .owl-dots .owl-dot.active {
        transform: scale(1.4);
        border: 2px solid #ffffff; }
      .values-slider .owl-dots .owl-dot:last-of-type {
        margin-right: 0; }

.infograf-b {
  display: flex;
  flex-wrap: wrap;
  margin: 20px -20px 30px; }
  .infograf-b .infograf-b-item {
    position: relative;
    width: 25%;
    padding: 0 20px;
    text-align: center; }
    .infograf-b .infograf-b-item:after {
      content: '';
      display: block;
      position: absolute;
      top: 50%;
      right: -32px;
      margin-top: -19px;
      width: 65px;
      height: 38px;
      background: url("../images/b-arrow.png") no-repeat center center;
      background-size: 100%; }
    .infograf-b .infograf-b-item:last-of-type:after {
      display: none; }
    @media only screen and (max-width: 750px) {
      .infograf-b .infograf-b-item {
        width: 50%; }
        .infograf-b .infograf-b-item:nth-of-type(2):after {
          display: none; } }
    @media only screen and (max-width: 400px) {
      .infograf-b .infograf-b-item:after {
        width: 40px;
        height: 30px;
        margin-top: -15px;
        right: -20px; } }

.fancybox-gallery-wrapper {
  display: flex;
  flex-wrap: wrap;
  font-size: 0; }
  .fancybox-gallery-wrapper .fancybox-gallery-item {
    display: inline-block;
    width: 33.333%;
    padding: 10px; }
    .fancybox-gallery-wrapper .fancybox-gallery-item a {
      border-bottom: none;
      display: block;
      margin-left: auto;
      margin-right: auto; }
      .fancybox-gallery-wrapper .fancybox-gallery-item a img {
        display: block;
        margin-left: auto;
        margin-right: auto; }
    @media only screen and (max-width: 700px) {
      .fancybox-gallery-wrapper .fancybox-gallery-item {
        width: 50%; } }
    @media only screen and (max-width: 500px) {
      .fancybox-gallery-wrapper .fancybox-gallery-item {
        width: 100%; } }

.fancybox-close {
  border-bottom: none; }

.header-menu {
  position: relative;
  background: url("../images/menu-bg.png") repeat left top, linear-gradient(to top, #dc9a17, #eeb942);
  height: 65px; }
  .header-menu:before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: -59px;
    width: 59px;
    height: 80px;
    background: url("../images/menu-left.png") no-repeat center center; }
  .header-menu:after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    right: -59px;
    width: 59px;
    height: 80px;
    background: url("../images/menu-right.png") no-repeat center center; }
  @media only screen and (max-width: 950px) {
    .header-menu {
      background: #e2a226; } }
  .header-menu:after {
    content: '';
    display: block;
    clear: both; }
  .header-menu ul {
    margin: 0;
    padding: 0;
    list-style: none;
    width: calc(50% - 155px); }
    .header-menu ul:first-of-type {
      float: left; }
    .header-menu ul:last-of-type {
      float: right;
      width: calc(37% - 155px); }
    .header-menu ul li {
      position: relative;
      display: inline-block;
      width: 32.5%;
      text-align: center;
      vertical-align: middle;
      line-height: 17px;
      height: 65px; }
      .header-menu ul li:before {
        content: '';
        display: inline-block;
        vertical-align: middle;
        width: 0;
        height: 100%; }
      .header-menu ul li:after {
        content: '';
        display: inline-block;
        position: absolute;
        right: 0;
        top: 0;
        width: 1px;
        height: 100%;
        background-color: #000000;
        border: 1px solid #ffffff;
        opacity: 0.1; }
      .header-menu ul li:last-of-type:after {
        display: none; }
      .header-menu ul li a {
        display: inline-block;
        vertical-align: middle;
        font-size: 17px;
        line-height: 17px;
        color: #ffffff;
        border-bottom: none;
        font-weight: 300;
        text-transform: uppercase; }
    .header-menu ul:last-of-type li:nth-of-type(1), .header-menu ul:last-of-type li:nth-of-type(2) {
      width: 49%; }
    .header-menu ul:last-of-type li:nth-of-type(2):after {
      display: none; }

.header-form {
  float: right;
  position: relative;
  width: 14%;
  padding: 13px 13px 10px 23px; }
  .header-form:after {
    content: '';
    position: absolute;
    top: -6px;
    left: 0px;
    display: block;
    width: 100%;
    height: 77px;
    background: url("../images/form-btn.png") no-repeat left top;
    background-size: 100% 100%;
    opacity: 1;
    border: none; }
  .header-form a {
    display: inline-block;
    vertical-align: middle;
    font-size: 17px;
    line-height: 17px;
    color: #ffffff;
    border-bottom: none;
    font-weight: 300;
    text-transform: uppercase;
    position: relative;
    z-index: 1;
    text-align: center; }
  @media only screen and (max-width: 950px) {
    .header-form {
      margin-right: 12%;
      width: 139px; } }
  @media only screen and (max-width: 850px) {
    .header-form {
      margin-right: 9%; } }
  @media only screen and (max-width: 750px) {
    .header-form {
      margin-right: 7%; } }
  @media only screen and (max-width: 700px) {
    .header-form {
      margin-right: 7%;
      width: 80px;
      padding: 15px 13px 10px 12px; }
      .header-form a {
        font-size: 12px; } }
  @media only screen and (max-width: 550px) {
    .header-form {
      margin-right: 5%; } }
  @media only screen and (max-width: 500px) {
    .header-form {
      margin-right: 5%;
      width: 70px;
      padding: 18px 13px 10px 9px; }
      .header-form a {
        font-size: 11px;
        line-height: 13px; } }
  @media only screen and (max-width: 350px) {
    .header-form {
      margin-right: 2%; } }

.footer-menu ul {
  margin: 0;
  padding: 0;
  list-style: none; }
  .footer-menu ul li {
    display: block; }
    .footer-menu ul li a {
      font-size: 15px;
      color: #ffffff;
      border-bottom: none; }

.button {
  border: none;
  background-color: #e59f2d;
  border-radius: 3px;
  height: 46px;
  padding-left: 20px;
  padding-right: 20px;
  color: #ffffff;
  text-transform: uppercase;
  font-size: 16px;
  transition: 0.2s;
  font-weight: 300; }
  .button:hover {
    background-color: #ac702b; }

.btn-scroll-top {
  display: none;
  position: fixed;
  right: 40px;
  top: 50%;
  margin-top: -27px;
  width: 23px;
  height: 54px;
  cursor: pointer;
  z-index: 20; }
  .btn-scroll-top.is-active {
    display: block; }

::-webkit-input-placeholder {
  color: #9f9f9f;
  -webkit-transition: all linear 0.2s 0s;
  -moz-transition: all linear 0.2s 0s;
  -ms-transition: all linear 0.2s 0s;
  -o-transition: all linear 0.2s 0s;
  transition: all linear 0.2s 0s; }

::-moz-placeholder {
  color: #9f9f9f;
  -webkit-transition: all linear 0.2s 0s;
  -moz-transition: all linear 0.2s 0s;
  -ms-transition: all linear 0.2s 0s;
  -o-transition: all linear 0.2s 0s;
  transition: all linear 0.2s 0s; }

:-moz-placeholder {
  color: #9f9f9f;
  -webkit-transition: all linear 0.2s 0s;
  -moz-transition: all linear 0.2s 0s;
  -ms-transition: all linear 0.2s 0s;
  -o-transition: all linear 0.2s 0s;
  transition: all linear 0.2s 0s; }

:-ms-input-placeholder {
  color: #9f9f9f;
  -webkit-transition: all linear 0.2s 0s;
  -moz-transition: all linear 0.2s 0s;
  -ms-transition: all linear 0.2s 0s;
  -o-transition: all linear 0.2s 0s;
  transition: all linear 0.2s 0s; }

input:invalid {
  box-shadow: none; }

[type="text"],
[type="email"],
[type="tel"],
textarea {
  width: 100%;
  border-radius: 3px;
  border: 1px solid #e59f2d;
  padding-left: 20px;
  margin-bottom: 30px;
  color: #000000; }

[type="text"],
[type="email"],
[type="tel"] {
  height: 46px; }

textarea {
  padding: 10px 20px; }

[type="submit"] {
  border: none;
  background-color: #e59f2d;
  border-radius: 3px;
  height: 46px;
  padding-left: 20px;
  padding-right: 20px;
  color: #ffffff;
  text-transform: uppercase;
  font-size: 16px;
  transition: 0.2s;
  font-weight: 300; }
  [type="submit"]:hover {
    background-color: #ac702b; }

.contact-form {
  border: 1px solid #e59f2d;
  padding: 30px 50px;
  text-align: center;
  margin-top: 30px; }
  @media only screen and (max-width: 500px) {
    .contact-form {
      padding: 20px; } }
  .contact-form .contact-form-tit {
    font-size: 26px;
    text-transform: uppercase;
    font-weight: 300;
    display: block;
    text-align: center;
    margin-bottom: 20px; }
    @media only screen and (max-width: 800px) {
      .contact-form .contact-form-tit {
        font-size: 24px; } }
    @media only screen and (max-width: 500px) {
      .contact-form .contact-form-tit {
        font-size: 20px; } }
  .contact-form input {
    display: inline-block;
    width: 33%;
    margin-bottom: 15px; }
    @media only screen and (max-width: 800px) {
      .contact-form input {
        width: 49%; } }
    @media only screen and (max-width: 550px) {
      .contact-form input {
        width: 100%; } }
  .contact-form .privacy-policy {
    display: block;
    text-align: center;
    font-weight: 300; }
    .contact-form .privacy-policy input {
      width: auto;
      margin-right: 5px; }

.breadcrumbs a:last-child::after, .breadcrumbs span:last-child::after {
  display: none; }

.window-overlay, .overlay_dark, .overlay_dark_ring {
  background: rgba(0, 0, 0, 0.7);
  bottom: 0;
  display: block;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 500;
  -webkit-animation-duration: 0.5s;
  animation-duration: 0.5s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both; }

.window-overlay {
  text-align: center; }
  .window-overlay:before {
    content: '';
    display: inline-block;
    width: 0;
    height: 100%;
    vertical-align: middle; }

#loading, #loading_ring {
  background: url("../images/loader.svg") no-repeat scroll center center transparent;
  background-size: 30px 30px;
  bottom: 0;
  display: block;
  height: 100%;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  width: 100%;
  z-index: 1120; }

.window {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  width: 90%;
  max-width: 500px;
  background: url("../images/container-bg.png") repeat left top;
  border: 2px solid #e59f2d;
  padding: 20px; }
  .window .window-close {
    width: 25px;
    height: 25px;
    background-color: #000000;
    border-radius: 50%;
    border: 2px solid #ffffff;
    position: absolute;
    top: -12px;
    right: -12px;
    cursor: pointer; }
    .window .window-close:after, .window .window-close:before {
      content: '';
      display: block;
      width: 15px;
      height: 2px;
      background-color: #ffffff;
      position: absolute;
      top: 9px;
      right: 3px;
      transform: rotate(45deg); }
    .window .window-close:after {
      transform: rotate(-45deg); }
  .window .window-tit {
    text-align: center;
    margin-bottom: 15px; }
    .window .window-tit span {
      font-size: 30px;
      display: block;
      text-align: center;
      margin-bottom: 10px;
      color: #ffffff;
      font-weight: 300; }
  .window p {
    text-align: center;
    margin-top: 15px; }

.main-wrapper {
  overflow: hidden; }

.container {
  width: calc(100% - 40px);
  max-width: 990px;
  margin: 0 auto;
  background: url("../images/container-bg.png") repeat left top;
  padding-top: 1px; }
  @media only screen and (max-width: 950px) {
    .container {
      width: 100%;
      padding: 1px 20px; } }

.content-wrapper .container {
  padding: 1px 20px 70px; }

@media print {
  h1, h2, h3, h4, h5, h6, p {
    margin: 0 0 10px 0;
    padding: 0; }
  h1, h2, h3, h4, h5, h6 {
    font-weight: bold; } }

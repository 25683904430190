//base
$body-background:             #341c18;

$transition-time:             0.2s;
//typography

$font-family-base:            'Open Sans', serif;
$font-size-base:              15px;
$font-color-base:             #ffffff;
$line-height-base:            20/14;

$links-size:                  15px;
$links-color:                 #e59f2d;

$font-light:                  300;
$font-regular:                400;
$font-semibold:               600;
$font-bold:                   700;

//headings
$headings-font-family:        $font-family-base !default;
$headings-font-weight:        $font-light !default;
$headings-line-height:        1.1 !default;
$headings-color:              #ffffff !default;

$font-size-h1:                40px;
$font-size-h2:                32px;
$font-size-h3:                28px;
$font-size-h4:                24px;
$font-size-h5:                18px;
$font-size-h6:                16px;

$color-h1:                    $headings-color;
$color-h2:                    $headings-color;
$color-h3:                    $headings-color;
$color-h4:                    $headings-color;
$color-h5:                    $headings-color;
$color-h6:                    $headings-color;


//layout
$container-width:            1230px !default;


//grid
$grid-columns:               12 !default;
$grid-gutter-width:          28px !default;

$screen-container:           $container-width;
$screen-xs:                  400px !default;
$screen-sm:                  768px !default;
$screen-md:                  1024px !default;
$screen-lg:                  1368px !default;


$screen-xs-min:              $screen-xs !default;
$screen-phone:               $screen-xs-min !default;
$screen-sm-min:              $screen-sm !default;
$screen-tablet:              $screen-sm-min !default;
$screen-md-min:              $screen-md !default;
$screen-desktop:             $screen-md-min !default;
$screen-lg-min:              $screen-lg !default;
$screen-lg-desktop:          $screen-lg-min !default;
$screen-xs-max:              ($screen-sm-min - 1) !default;
$screen-sm-max:              ($screen-md-min - 1) !default;
$screen-md-max:              ($screen-lg-min - 1) !default;

// Hover effects breakpoint

$hover-breakpoint:            960px;

// Placeholder color

$placeholder-color: #9f9f9f;



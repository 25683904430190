.nice_table{
  border-collapse: collapse;
  font-size: 14px;
  th, td{
    border: 1px solid #d1e0e8;
    color: #0d0d0d;
    font-size: 15px;
  }
  th{
    background: #eff0f4;
    font-size: 16px;
  }
  tr:nth-child(even){
    background: #eff0f4;
  }
}

.with_marker{
  list-style-image: url("../images/with_marker.png");
  list-style-position: outside;
  margin: 33px 0 40px 24px;
  li{
    line-height: 24px;
  }
}


.error{
  clear: both;

  ul{
    list-style: none;
    margin: 0;
    padding: 0;

    li{
      padding: 3px 0 0 40px;
      font-size: 14px;
      color: red;
    }

  }

}

table {
  &.noborder {
    border: none;
    td,th {
      border: none;
    }
  }
  &.cellpadding_5 {
    td,th {
      padding: 5px;
    }
  }
  &.cellpadding_7 {
    td,th {
      padding: 7px;
    }
  }
  &.cellpadding_10 {
    td,th {
      padding: 10px;
    }
  }
  &.justify {
    width: 100%;
  }
}

.table-wrapper{
  overflow: auto;
  width: 100%;
  .table-wrapper{
    overflow: visible;
  }
}

.title,
.title_pole, .title_field {
  display: none!important;
}

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, img, ins, kbd, q, s, samp,
small, strike, sub, sup, tt, var, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block;
}
body {
  line-height: 1;
}
blockquote, q {
  quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none;
}

html {
  font-family: sans-serif; // 1
  -ms-text-size-adjust: 100%; // 2
  -webkit-text-size-adjust: 100%; // 2
}

body {
  margin: 0;
}


article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
main,
menu,
nav,
section,
summary {
  display: block;
}


audio,
canvas,
progress,
video {
  display: inline-block; // 1
  vertical-align: baseline; // 2
}

audio:not([controls]) {
  display: none;
  height: 0;
}


[hidden],
template {
  display: none;
}


a {
  background-color: transparent;
}

a:active,
a:hover {
  outline: 0;
}


abbr[title] {
  border-bottom: 1px dotted;
}

b,
strong {
  font-weight: bold;
}


dfn {
  font-style: italic;
}



h1 {
  font-size: 2em;
  margin: 0.67em 0;
}


mark {
  background: #ff0;
  color: #000;
}



small {
  font-size: 80%;
}

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sup {
  top: -0.5em;
}

sub {
  bottom: -0.25em;
}


img {
  border: 0;
}


svg:not(:root) {
  overflow: hidden;
}


figure {
  margin: 1em 40px;
}


hr {
  box-sizing: content-box;
  height: 0;
}


pre {
  overflow: auto;
}


code,
kbd,
pre,
samp {
  font-family: monospace, monospace;
  font-size: 1em;
}


button,
input,
optgroup,
select,
textarea {
  color: inherit; // 1
  font: inherit; // 2
  margin: 0; // 3
}


button {
  overflow: visible;
}


button,
select {
  text-transform: none;
}


button,
html input[type="button"], // 1
input[type="reset"],
[type="submit"] {
  -webkit-appearance: button; // 2
  cursor: pointer; // 3
}

button[disabled],
html input[disabled] {
  cursor: default;
}


button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0;
}



input {
  line-height: normal;
}

input[type="checkbox"],
input[type="radio"] {
  box-sizing: border-box; // 1
  padding: 0; // 2
}


input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  height: auto;
}

input[type="search"] {
  -webkit-appearance: textfield; // 1
  box-sizing: content-box; //2
}

input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

fieldset {
  border: 1px solid #c0c0c0;
  margin: 0 2px;
  padding: 0.35em 0.625em 0.75em;
}

legend {
  border: 0; // 1
  padding: 0; // 2
}

textarea {
  overflow: auto;
}

optgroup {
  font-weight: bold;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

td,
th {
  padding: 0;
}

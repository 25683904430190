@include placeholder($placeholder-color){
    @include transition();
}

input:invalid{
    box-shadow:none;
}

[type="text"],
[type="email"],
[type="tel"],
textarea{
    width: 100%;
    border-radius: 3px;
    border: 1px solid #e59f2d;
    padding-left: 20px;
    margin-bottom: 30px;
    color: #000000;
}
[type="text"],
[type="email"],
[type="tel"]{
    height: 46px;
}

textarea{
    padding: 10px 20px;
}

[type="submit"]{
    border: none;
    background-color: #e59f2d;
    border-radius: 3px;
    height: 46px;
    padding-left: 20px;
    padding-right: 20px;
    color: #ffffff;
    text-transform: uppercase;
    font-size: 16px;
    transition: 0.2s;
    font-weight: 300;
    &:hover{
        background-color: #ac702b;
    }
}

.contact-form{
    border: 1px solid #e59f2d;
    padding: 30px 50px;
    text-align: center;
    margin-top: 30px;
    @include mediaMax(500px){
        padding: 20px;
    }
    .contact-form-tit{
        font-size: 26px;
        text-transform: uppercase;
        font-weight: 300;
        display: block;
        text-align: center;
        margin-bottom: 20px;
        @include mediaMax(800px){
            font-size: 24px;
        }
        @include mediaMax(500px){
            font-size: 20px;
        }
    }
    input{
        display: inline-block;
        width: 33%;
        margin-bottom: 15px;
        @include mediaMax(800px){
            width: 49%;
        }
        @include mediaMax(550px){
            width: 100%;
        }
    }
    .privacy-policy{
        display: block;
        text-align: center;
        font-weight: 300;
        input{
            width: auto;
            margin-right: 5px;
        }
    }
}



// SCSS variables are information about icon's compiled state, stored under its original file name
//
// .icon-home {
//   width: $icon-home-width;
// }
//
// The large array-like variables contain all information about a single icon
// $icon-home: x y offset_x offset_y width height total_width total_height image_path;
//
// At the bottom of this section, we provide information about the spritesheet itself
// $spritesheet: width height image $spritesheet-sprites;
$bulet-active-name: 'bulet-active';
$bulet-active-x: 127px;
$bulet-active-y: 104px;
$bulet-active-offset-x: -127px;
$bulet-active-offset-y: -104px;
$bulet-active-width: 12px;
$bulet-active-height: 12px;
$bulet-active-total-width: 139px;
$bulet-active-total-height: 130px;
$bulet-active-image: '../images/icons.png';
$bulet-active: (127px, 104px, -127px, -104px, 12px, 12px, 139px, 130px, '../images/icons.png', 'bulet-active', );
$bulet-name: 'bulet';
$bulet-x: 127px;
$bulet-y: 90px;
$bulet-offset-x: -127px;
$bulet-offset-y: -90px;
$bulet-width: 12px;
$bulet-height: 12px;
$bulet-total-width: 139px;
$bulet-total-height: 130px;
$bulet-image: '../images/icons.png';
$bulet: (127px, 90px, -127px, -90px, 12px, 12px, 139px, 130px, '../images/icons.png', 'bulet', );
$calendar-white-name: 'calendar-white';
$calendar-white-x: 79px;
$calendar-white-y: 0px;
$calendar-white-offset-x: -79px;
$calendar-white-offset-y: 0px;
$calendar-white-width: 24px;
$calendar-white-height: 24px;
$calendar-white-total-width: 139px;
$calendar-white-total-height: 130px;
$calendar-white-image: '../images/icons.png';
$calendar-white: (79px, 0px, -79px, 0px, 24px, 24px, 139px, 130px, '../images/icons.png', 'calendar-white', );
$calendar-name: 'calendar';
$calendar-x: 52px;
$calendar-y: 27px;
$calendar-offset-x: -52px;
$calendar-offset-y: -27px;
$calendar-width: 24px;
$calendar-height: 24px;
$calendar-total-width: 139px;
$calendar-total-height: 130px;
$calendar-image: '../images/icons.png';
$calendar: (52px, 27px, -52px, -27px, 24px, 24px, 139px, 130px, '../images/icons.png', 'calendar', );
$call-white-name: 'call-white';
$call-white-x: 59px;
$call-white-y: 104px;
$call-white-offset-x: -59px;
$call-white-offset-y: -104px;
$call-white-width: 15px;
$call-white-height: 15px;
$call-white-total-width: 139px;
$call-white-total-height: 130px;
$call-white-image: '../images/icons.png';
$call-white: (59px, 104px, -59px, -104px, 15px, 15px, 139px, 130px, '../images/icons.png', 'call-white', );
$call-name: 'call';
$call-x: 42px;
$call-y: 104px;
$call-offset-x: -42px;
$call-offset-y: -104px;
$call-width: 15px;
$call-height: 15px;
$call-total-width: 139px;
$call-total-height: 130px;
$call-image: '../images/icons.png';
$call: (42px, 104px, -42px, -104px, 15px, 15px, 139px, 130px, '../images/icons.png', 'call', );
$contact-adress-name: 'contact-adress';
$contact-adress-x: 52px;
$contact-adress-y: 0px;
$contact-adress-offset-x: -52px;
$contact-adress-offset-y: 0px;
$contact-adress-width: 25px;
$contact-adress-height: 25px;
$contact-adress-total-width: 139px;
$contact-adress-total-height: 130px;
$contact-adress-image: '../images/icons.png';
$contact-adress: (52px, 0px, -52px, 0px, 25px, 25px, 139px, 130px, '../images/icons.png', 'contact-adress', );
$contact-email-name: 'contact-email';
$contact-email-x: 25px;
$contact-email-y: 56px;
$contact-email-offset-x: -25px;
$contact-email-offset-y: -56px;
$contact-email-width: 24px;
$contact-email-height: 19px;
$contact-email-total-width: 139px;
$contact-email-total-height: 130px;
$contact-email-image: '../images/icons.png';
$contact-email: (25px, 56px, -25px, -56px, 24px, 19px, 139px, 130px, '../images/icons.png', 'contact-email', );
$contact-fax-name: 'contact-fax';
$contact-fax-x: 105px;
$contact-fax-y: 18px;
$contact-fax-offset-x: -105px;
$contact-fax-offset-y: -18px;
$contact-fax-width: 20px;
$contact-fax-height: 16px;
$contact-fax-total-width: 139px;
$contact-fax-total-height: 130px;
$contact-fax-image: '../images/icons.png';
$contact-fax: (105px, 18px, -105px, -18px, 20px, 16px, 139px, 130px, '../images/icons.png', 'contact-fax', );
$contact-houre-name: 'contact-houre';
$contact-houre-x: 79px;
$contact-houre-y: 26px;
$contact-houre-offset-x: -79px;
$contact-houre-offset-y: -26px;
$contact-houre-width: 23px;
$contact-houre-height: 23px;
$contact-houre-total-width: 139px;
$contact-houre-total-height: 130px;
$contact-houre-image: '../images/icons.png';
$contact-houre: (79px, 26px, -79px, -26px, 23px, 23px, 139px, 130px, '../images/icons.png', 'contact-houre', );
$contact-phone-name: 'contact-phone';
$contact-phone-x: 0px;
$contact-phone-y: 56px;
$contact-phone-offset-x: 0px;
$contact-phone-offset-y: -56px;
$contact-phone-width: 23px;
$contact-phone-height: 23px;
$contact-phone-total-width: 139px;
$contact-phone-total-height: 130px;
$contact-phone-image: '../images/icons.png';
$contact-phone: (0px, 56px, 0px, -56px, 23px, 23px, 139px, 130px, '../images/icons.png', 'contact-phone', );
$contact-print-name: 'contact-print';
$contact-print-x: 74px;
$contact-print-y: 81px;
$contact-print-offset-x: -74px;
$contact-print-offset-y: -81px;
$contact-print-width: 20px;
$contact-print-height: 16px;
$contact-print-total-width: 139px;
$contact-print-total-height: 130px;
$contact-print-image: '../images/icons.png';
$contact-print: (74px, 81px, -74px, -81px, 20px, 16px, 139px, 130px, '../images/icons.png', 'contact-print', );
$copntact-email-name: 'copntact-email';
$copntact-email-x: 51px;
$copntact-email-y: 56px;
$copntact-email-offset-x: -51px;
$copntact-email-offset-y: -56px;
$copntact-email-width: 24px;
$copntact-email-height: 19px;
$copntact-email-total-width: 139px;
$copntact-email-total-height: 130px;
$copntact-email-image: '../images/icons.png';
$copntact-email: (51px, 56px, -51px, -56px, 24px, 19px, 139px, 130px, '../images/icons.png', 'copntact-email', );
$eyes-name: 'eyes';
$eyes-x: 76px;
$eyes-y: 104px;
$eyes-offset-x: -76px;
$eyes-offset-y: -104px;
$eyes-width: 16px;
$eyes-height: 12px;
$eyes-total-width: 139px;
$eyes-total-height: 130px;
$eyes-image: '../images/icons.png';
$eyes: (76px, 104px, -76px, -104px, 16px, 12px, 139px, 130px, '../images/icons.png', 'eyes', );
$fb-footer-name: 'fb-footer';
$fb-footer-x: 127px;
$fb-footer-y: 68px;
$fb-footer-offset-x: -127px;
$fb-footer-offset-y: -68px;
$fb-footer-width: 11px;
$fb-footer-height: 20px;
$fb-footer-total-width: 139px;
$fb-footer-total-height: 130px;
$fb-footer-image: '../images/icons.png';
$fb-footer: (127px, 68px, -127px, -68px, 11px, 20px, 139px, 130px, '../images/icons.png', 'fb-footer', );
$fb-header-name: 'fb-header';
$fb-header-x: 127px;
$fb-header-y: 46px;
$fb-header-offset-x: -127px;
$fb-header-offset-y: -46px;
$fb-header-width: 11px;
$fb-header-height: 20px;
$fb-header-total-width: 139px;
$fb-header-total-height: 130px;
$fb-header-image: '../images/icons.png';
$fb-header: (127px, 46px, -127px, -46px, 11px, 20px, 139px, 130px, '../images/icons.png', 'fb-header', );
$gplus-footer-name: 'gplus-footer';
$gplus-footer-x: 77px;
$gplus-footer-y: 56px;
$gplus-footer-offset-x: -77px;
$gplus-footer-offset-y: -56px;
$gplus-footer-width: 26px;
$gplus-footer-height: 16px;
$gplus-footer-total-width: 139px;
$gplus-footer-total-height: 130px;
$gplus-footer-image: '../images/icons.png';
$gplus-footer: (77px, 56px, -77px, -56px, 26px, 16px, 139px, 130px, '../images/icons.png', 'gplus-footer', );
$gplus-header-name: 'gplus-header';
$gplus-header-x: 46px;
$gplus-header-y: 81px;
$gplus-header-offset-x: -46px;
$gplus-header-offset-y: -81px;
$gplus-header-width: 26px;
$gplus-header-height: 16px;
$gplus-header-total-width: 139px;
$gplus-header-total-height: 130px;
$gplus-header-image: '../images/icons.png';
$gplus-header: (46px, 81px, -46px, -81px, 26px, 16px, 139px, 130px, '../images/icons.png', 'gplus-header', );
$inst-footer-name: 'inst-footer';
$inst-footer-x: 23px;
$inst-footer-y: 81px;
$inst-footer-offset-x: -23px;
$inst-footer-offset-y: -81px;
$inst-footer-width: 21px;
$inst-footer-height: 21px;
$inst-footer-total-width: 139px;
$inst-footer-total-height: 130px;
$inst-footer-image: '../images/icons.png';
$inst-footer: (23px, 81px, -23px, -81px, 21px, 21px, 139px, 130px, '../images/icons.png', 'inst-footer', );
$inst-header-name: 'inst-header';
$inst-header-x: 0px;
$inst-header-y: 81px;
$inst-header-offset-x: 0px;
$inst-header-offset-y: -81px;
$inst-header-width: 21px;
$inst-header-height: 21px;
$inst-header-total-width: 139px;
$inst-header-total-height: 130px;
$inst-header-image: '../images/icons.png';
$inst-header: (0px, 81px, 0px, -81px, 21px, 21px, 139px, 130px, '../images/icons.png', 'inst-header', );
$mail-white-name: 'mail-white';
$mail-white-x: 105px;
$mail-white-y: 54px;
$mail-white-offset-x: -105px;
$mail-white-offset-y: -54px;
$mail-white-width: 20px;
$mail-white-height: 16px;
$mail-white-total-width: 139px;
$mail-white-total-height: 130px;
$mail-white-image: '../images/icons.png';
$mail-white: (105px, 54px, -105px, -54px, 20px, 16px, 139px, 130px, '../images/icons.png', 'mail-white', );
$mail-name: 'mail';
$mail-x: 105px;
$mail-y: 72px;
$mail-offset-x: -105px;
$mail-offset-y: -72px;
$mail-width: 20px;
$mail-height: 16px;
$mail-total-width: 139px;
$mail-total-height: 130px;
$mail-image: '../images/icons.png';
$mail: (105px, 72px, -105px, -72px, 20px, 16px, 139px, 130px, '../images/icons.png', 'mail', );
$phone-arrow-name: 'phone-arrow';
$phone-arrow-x: 24px;
$phone-arrow-y: 122px;
$phone-arrow-offset-x: -24px;
$phone-arrow-offset-y: -122px;
$phone-arrow-width: 10px;
$phone-arrow-height: 8px;
$phone-arrow-total-width: 139px;
$phone-arrow-total-height: 130px;
$phone-arrow-image: '../images/icons.png';
$phone-arrow: (24px, 122px, -24px, -122px, 10px, 8px, 139px, 130px, '../images/icons.png', 'phone-arrow', );
$read-more-dots-hover-name: 'read-more-dots-hover';
$read-more-dots-hover-x: 46px;
$read-more-dots-hover-y: 99px;
$read-more-dots-hover-offset-x: -46px;
$read-more-dots-hover-offset-y: -99px;
$read-more-dots-hover-width: 15px;
$read-more-dots-hover-height: 3px;
$read-more-dots-hover-total-width: 139px;
$read-more-dots-hover-total-height: 130px;
$read-more-dots-hover-image: '../images/icons.png';
$read-more-dots-hover: (46px, 99px, -46px, -99px, 15px, 3px, 139px, 130px, '../images/icons.png', 'read-more-dots-hover', );
$read-more-dots-name: 'read-more-dots';
$read-more-dots-x: 79px;
$read-more-dots-y: 51px;
$read-more-dots-offset-x: -79px;
$read-more-dots-offset-y: -51px;
$read-more-dots-width: 15px;
$read-more-dots-height: 3px;
$read-more-dots-total-width: 139px;
$read-more-dots-total-height: 130px;
$read-more-dots-image: '../images/icons.png';
$read-more-dots: (79px, 51px, -79px, -51px, 15px, 3px, 139px, 130px, '../images/icons.png', 'read-more-dots', );
$show-all-arrow-hover-name: 'show-all-arrow-hover';
$show-all-arrow-hover-x: 110px;
$show-all-arrow-hover-y: 104px;
$show-all-arrow-hover-offset-x: -110px;
$show-all-arrow-hover-offset-y: -104px;
$show-all-arrow-hover-width: 14px;
$show-all-arrow-hover-height: 10px;
$show-all-arrow-hover-total-width: 139px;
$show-all-arrow-hover-total-height: 130px;
$show-all-arrow-hover-image: '../images/icons.png';
$show-all-arrow-hover: (110px, 104px, -110px, -104px, 14px, 10px, 139px, 130px, '../images/icons.png', 'show-all-arrow-hover', );
$show-all-arrow-name: 'show-all-arrow';
$show-all-arrow-x: 94px;
$show-all-arrow-y: 104px;
$show-all-arrow-offset-x: -94px;
$show-all-arrow-offset-y: -104px;
$show-all-arrow-width: 14px;
$show-all-arrow-height: 10px;
$show-all-arrow-total-width: 139px;
$show-all-arrow-total-height: 130px;
$show-all-arrow-image: '../images/icons.png';
$show-all-arrow: (94px, 104px, -94px, -104px, 14px, 10px, 139px, 130px, '../images/icons.png', 'show-all-arrow', );
$slider-arrow-hover-name: 'slider-arrow-hover';
$slider-arrow-hover-x: 127px;
$slider-arrow-hover-y: 0px;
$slider-arrow-hover-offset-x: -127px;
$slider-arrow-hover-offset-y: 0px;
$slider-arrow-hover-width: 12px;
$slider-arrow-hover-height: 21px;
$slider-arrow-hover-total-width: 139px;
$slider-arrow-hover-total-height: 130px;
$slider-arrow-hover-image: '../images/icons.png';
$slider-arrow-hover: (127px, 0px, -127px, 0px, 12px, 21px, 139px, 130px, '../images/icons.png', 'slider-arrow-hover', );
$slider-arrow-name: 'slider-arrow';
$slider-arrow-x: 127px;
$slider-arrow-y: 23px;
$slider-arrow-offset-x: -127px;
$slider-arrow-offset-y: -23px;
$slider-arrow-width: 12px;
$slider-arrow-height: 21px;
$slider-arrow-total-width: 139px;
$slider-arrow-total-height: 130px;
$slider-arrow-image: '../images/icons.png';
$slider-arrow: (127px, 23px, -127px, -23px, 12px, 21px, 139px, 130px, '../images/icons.png', 'slider-arrow', );
$star-white-name: 'star-white';
$star-white-x: 25px;
$star-white-y: 0px;
$star-white-offset-x: -25px;
$star-white-offset-y: 0px;
$star-white-width: 25px;
$star-white-height: 25px;
$star-white-total-width: 139px;
$star-white-total-height: 130px;
$star-white-image: '../images/icons.png';
$star-white: (25px, 0px, -25px, 0px, 25px, 25px, 139px, 130px, '../images/icons.png', 'star-white', );
$star-name: 'star';
$star-x: 25px;
$star-y: 27px;
$star-offset-x: -25px;
$star-offset-y: -27px;
$star-width: 25px;
$star-height: 25px;
$star-total-width: 139px;
$star-total-height: 130px;
$star-image: '../images/icons.png';
$star: (25px, 27px, -25px, -27px, 25px, 25px, 139px, 130px, '../images/icons.png', 'star', );
$to-top-name: 'to-top';
$to-top-x: 0px;
$to-top-y: 0px;
$to-top-offset-x: 0px;
$to-top-offset-y: 0px;
$to-top-width: 23px;
$to-top-height: 54px;
$to-top-total-width: 139px;
$to-top-total-height: 130px;
$to-top-image: '../images/icons.png';
$to-top: (0px, 0px, 0px, 0px, 23px, 54px, 139px, 130px, '../images/icons.png', 'to-top', );
$top-menu-arrow-hover-name: 'top-menu-arrow-hover';
$top-menu-arrow-hover-x: 0px;
$top-menu-arrow-hover-y: 122px;
$top-menu-arrow-hover-offset-x: 0px;
$top-menu-arrow-hover-offset-y: -122px;
$top-menu-arrow-hover-width: 10px;
$top-menu-arrow-hover-height: 8px;
$top-menu-arrow-hover-total-width: 139px;
$top-menu-arrow-hover-total-height: 130px;
$top-menu-arrow-hover-image: '../images/icons.png';
$top-menu-arrow-hover: (0px, 122px, 0px, -122px, 10px, 8px, 139px, 130px, '../images/icons.png', 'top-menu-arrow-hover', );
$top-menu-arrow-name: 'top-menu-arrow';
$top-menu-arrow-x: 12px;
$top-menu-arrow-y: 122px;
$top-menu-arrow-offset-x: -12px;
$top-menu-arrow-offset-y: -122px;
$top-menu-arrow-width: 10px;
$top-menu-arrow-height: 8px;
$top-menu-arrow-total-width: 139px;
$top-menu-arrow-total-height: 130px;
$top-menu-arrow-image: '../images/icons.png';
$top-menu-arrow: (12px, 122px, -12px, -122px, 10px, 8px, 139px, 130px, '../images/icons.png', 'top-menu-arrow', );
$tw-footer-name: 'tw-footer';
$tw-footer-x: 21px;
$tw-footer-y: 104px;
$tw-footer-offset-x: -21px;
$tw-footer-offset-y: -104px;
$tw-footer-width: 19px;
$tw-footer-height: 16px;
$tw-footer-total-width: 139px;
$tw-footer-total-height: 130px;
$tw-footer-image: '../images/icons.png';
$tw-footer: (21px, 104px, -21px, -104px, 19px, 16px, 139px, 130px, '../images/icons.png', 'tw-footer', );
$tw-header-name: 'tw-header';
$tw-header-x: 0px;
$tw-header-y: 104px;
$tw-header-offset-x: 0px;
$tw-header-offset-y: -104px;
$tw-header-width: 19px;
$tw-header-height: 16px;
$tw-header-total-width: 139px;
$tw-header-total-height: 130px;
$tw-header-image: '../images/icons.png';
$tw-header: (0px, 104px, 0px, -104px, 19px, 16px, 139px, 130px, '../images/icons.png', 'tw-header', );
$u-footer-name: 'u-footer';
$u-footer-x: 105px;
$u-footer-y: 0px;
$u-footer-offset-x: -105px;
$u-footer-offset-y: 0px;
$u-footer-width: 20px;
$u-footer-height: 16px;
$u-footer-total-width: 139px;
$u-footer-total-height: 130px;
$u-footer-image: '../images/icons.png';
$u-footer: (105px, 0px, -105px, 0px, 20px, 16px, 139px, 130px, '../images/icons.png', 'u-footer', );
$u-header-name: 'u-header';
$u-header-x: 105px;
$u-header-y: 36px;
$u-header-offset-x: -105px;
$u-header-offset-y: -36px;
$u-header-width: 20px;
$u-header-height: 16px;
$u-header-total-width: 139px;
$u-header-total-height: 130px;
$u-header-image: '../images/icons.png';
$u-header: (105px, 36px, -105px, -36px, 20px, 16px, 139px, 130px, '../images/icons.png', 'u-header', );
$year-name: 'year';
$year-x: 105px;
$year-y: 90px;
$year-offset-x: -105px;
$year-offset-y: -90px;
$year-width: 16px;
$year-height: 12px;
$year-total-width: 139px;
$year-total-height: 130px;
$year-image: '../images/icons.png';
$year: (105px, 90px, -105px, -90px, 16px, 12px, 139px, 130px, '../images/icons.png', 'year', );
$spritesheet-width: 139px;
$spritesheet-height: 130px;
$spritesheet-image: '../images/icons.png';
$spritesheet-sprites: ($bulet-active, $bulet, $calendar-white, $calendar, $call-white, $call, $contact-adress, $contact-email, $contact-fax, $contact-houre, $contact-phone, $contact-print, $copntact-email, $eyes, $fb-footer, $fb-header, $gplus-footer, $gplus-header, $inst-footer, $inst-header, $mail-white, $mail, $phone-arrow, $read-more-dots-hover, $read-more-dots, $show-all-arrow-hover, $show-all-arrow, $slider-arrow-hover, $slider-arrow, $star-white, $star, $to-top, $top-menu-arrow-hover, $top-menu-arrow, $tw-footer, $tw-header, $u-footer, $u-header, $year, );
$spritesheet: (139px, 130px, '../images/icons.png', $spritesheet-sprites, );

// The provided mixins are intended to be used with the array-like variables
//
// .icon-home {
//   @include sprite-width($icon-home);
// }
//
// .icon-email {
//   @include sprite($icon-email);
// }
//
// Example usage in HTML:
//
// `display: block` sprite:
// <div class="icon-home"></div>
//
// To change `display` (e.g. `display: inline-block;`), we suggest using a common CSS class:
//
// // CSS
// .icon {
//   display: inline-block;
// }
//
// // HTML
// <i class="icon icon-home"></i>
@mixin sprite-width($sprite) {
  width: nth($sprite, 5);
}

@mixin sprite-height($sprite) {
  height: nth($sprite, 6);
}

@mixin sprite-position($sprite) {
  $sprite-offset-x: nth($sprite, 3);
  $sprite-offset-y: nth($sprite, 4);
  background-position: $sprite-offset-x  $sprite-offset-y;
}

@mixin sprite-image($sprite) {
  $sprite-image: nth($sprite, 9);
  background-image: url(#{$sprite-image});
}

@mixin sprite($sprite) {
  @include sprite-image($sprite);
  @include sprite-position($sprite);
  @include sprite-width($sprite);
  @include sprite-height($sprite);
}

// The `sprites` mixin generates identical output to the CSS template
//   but can be overridden inside of SCSS
//
// @include sprites($spritesheet-sprites);
@mixin sprites($sprites) {
  @each $sprite in $sprites {
    $sprite-name: nth($sprite, 10);
    .#{$sprite-name} {
      @include sprite($sprite);
    }
  }
}

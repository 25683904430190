@import "_mixins/grid-mixins";


.row {
  @include make-row;
}

@include make-grid-columns;
@include make-col-grid();

@media (min-width: $screen-xs-min) {
  @include make-grid(xs);
}
@media (min-width: $screen-sm-min) {
  @include make-grid(sm);
}

@media (min-width: $screen-md-min) {
  @include make-grid(md);
}

@media (min-width: $screen-lg-min) {
  @include make-grid(lg);
}
@media (min-width: $screen-container) {
  @include make-grid(container);
}

/********************************************************************************************/
/* Utitilites-Grid--------------------------------------------------------------------------*/
/* адаптивна сетка                                                                          */
/* Основная сетка строится на flexbox'ах  + фолбэки на inline-block'ах                      */
/* Фолбэки цепляются по классу no-flexbox, который добавляет modernizer                     */
/********************************************************************************************/
$grid-type: float;
$grid-gap: 30px;
$grid-screen: (
        xl: 1280px,  //
        lg: 1024px,  // планшеты в альбомной ориентации
        md: 768px,   // планшеты в портретной ориентации / телефоны в альбомной
        sm: 420px,   // большине телефоны  в портретной ориентации
        xs: 320px    // iphone5 в портретной ориентации
);


/*==========================================================================================*/
/*  Основа  */
/*==========================================================================================*/

.grid{
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;

  margin-left: -$grid-gap/2;
  margin-right: -$grid-gap/2;

  [class *= "col-"] {
    margin-left: $grid-gap/2;
    margin-right: $grid-gap/2;
    margin-bottom: 20px;
  }

  .row{
    display: flex;
    width: 100%;
  }
}


/*==========================================================================================*/
/*  Генерируем классы для инлайнового варианта  */
/*==========================================================================================*/

/* геренирует сетку для разрешения $screen-prefix */
@mixin grid-sizer($screen-prefix){
  $total-columns: 6; // число колонок
  $col-widths: ();   // вспомогательный массив, в котором будут храниться размеры
  // генерируем дробные размеры
  @for $i from 1 through $total-columns {
    @for $j from 1 through $i {
      // определяем текущий размеры
      $width: ($j/$i);
      // если такого размера ещё не было, то создаём стили
      @if not index($col-widths, $width) {
        $width-col: 'calc(' + $width * 100% + ' - ' +  $grid-gap + ')';
        $width-offset: 'calc(' + $width * 100% + ' + ' +  $grid-gap/2 + ')';

        @if($screen-prefix != ''){
          // разделение на медиа
          .col-#{$screen-prefix}-#{$j}-#{$i} {
            width:  unquote($width-col);
          }

          .col-#{$screen-prefix}-offset-#{$j}-#{$i} {
            margin-left: unquote($width-offset);
          }
        }


        @else {
          // и стандартные
          .col-#{$j}-#{$i} {
            width:  unquote($width-col);
          }
          .col-offset-#{$j}-#{$i} {
            margin-left: unquote($width-offset);
          }
        }

        // и записываем размер в массив существуюих размеров
        $col-widths: append($col-widths, $width, comma);
      }

    }
  }
}
/* перебирает все разрешения $grid-screen и запускает для них  @mixin grid-sizer*/
@mixin grid-sizer-media($grid-screen){
  // проходим про всем разрешениям и генерируем медиа запросы
  @each $screen-prefix, $screen-size in $grid-screen {
    @media (max-width: $screen-size) {
      @include grid-sizer($screen-prefix);
    }
  }

}

/* геренируем инлайновую сетку */
@include grid-sizer('');
@include grid-sizer-media($grid-screen);

.icon {
  display: inline-block;
  line-height: 1;
  font-size: 1px;
}
.icon-svg{
  fill: none;
  stroke: #000;
  width: 1em;
  height: 1em;
}
@import "icons-img";
@import "icons-svg";

@include sprites($spritesheet-sprites);

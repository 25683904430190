* {
  outline: none;
  box-sizing:border-box;
  padding: 0;
  margin: 0;
}

html, body{
  font-family: $font-family-base;
  font-size: $font-size-base;
  color: $font-color-base;
  font-weight: $font-regular;
  line-height: $line-height-base;
  background: url("../images/body-bg.png") repeat left top,$body-background;
}

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  font-family: $headings-font-family;
  font-weight: $headings-font-weight;
  line-height: $headings-line-height;
  color: $headings-color;
  text-align: center;
  @include mediaMax(400px){
    hyphens: auto;
  }
}
h1, .h1 {
  font-size: $font-size-h1;
  color: $color-h1;
  @include mediaMax(800px){
    font-size: 34px;
  }
  @include mediaMax(400px){
    font-size: 30px;
  }
}
h2, .h2 {
  font-size: $font-size-h2;
  color: $color-h2;
  margin: 25px 0 20px;
  @include mediaMax(800px){
    font-size: 30px;
  }
  @include mediaMax(400px){
    font-size: 26px;
  }
}
h3, .h3 {
  font-size: $font-size-h3;
  color: $color-h3;
  margin: 25px 0 20px;
  @include mediaMax(800px){
    font-size: 28px;
  }
  @include mediaMax(400px){
    font-size: 22px;
  }
}
h4, .h4 {
  font-size: $font-size-h4;
  color: $color-h4;
  margin: 15px 0 10px;
  @include mediaMax(800px){
    font-size: 22px;
  }
  @include mediaMax(400px){
    font-size: 18px;
  }
}
h5, .h5 {
  font-size: $font-size-h5;
  color: $color-h5;
}
h6, .h6 {
  font-size: $font-size-h6;
  color: $color-h6;
}

p{
  line-height: 22px;
  font-size: 16px;
  margin-bottom: 26px;
  color: #ffffff;
  font-weight: 300;
  text-align: left;
}

a{
  font-size: $links-size;
  color: $links-color;
  text-decoration: none;
  border-bottom: 1px solid $links-color;
  @include transition;
  font-weight: 300;
  &:hover{
    border-bottom-color: transparent;
  }
}

img {
  border: none;
  max-width: 100%;
  max-height: 100%;
}

table {
  border: 1px solid #000000;
  padding: 0;
  margin: 40px 0 30px;
  td,th {
    border: 1px solid #000000;
    font-weight: 300;
  }
  th {
    text-align: center;
  }
}
ul,ol{
  margin: 20px 0;
  list-style-position: inside;
  li{
    color: #ffffff;
    font-weight: 300;
    padding: 3px 0;
  }
}

.index-page{

  h1, h2, h3, h4, h5, h6,
  .h1, .h2, .h3, .h4, .h5, .h6 {

  }
  h1, .h1 {

  }
  h2, .h2 {

  }
  h3, .h3 {

  }
  h4, .h4 {

  }
  h5, .h5 {

  }
  h6, .h6 {

  }

  p{

  }

  a{

  }

}
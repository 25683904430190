@mixin clearfix(){
  &:after {
    content: '';
    display: block;
    clear: both;
  }
}
@mixin clear(){
  &:after {
    content: '';
    display: block;
    clear: both;
  }
}
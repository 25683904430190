.window-overlay, .overlay_dark, .overlay_dark_ring {
  background: rgba(0, 0, 0, 0.7);
  bottom: 0;
  display: block;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 500;
  -webkit-animation-duration: 0.5s;
  animation-duration: 0.5s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}
.window-overlay{
  text-align: center;
  &:before{
    content: '';
    display: inline-block;
    width: 0;
    height: 100%;
    vertical-align: middle;
  }
}
#loading, #loading_ring {
  background: url("../images/loader.svg") no-repeat scroll center center transparent;
  background-size: 30px 30px;
  bottom: 0;
  display: block;
  height: 100%;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  width: 100%;
  z-index: 1120;
}
.window{
  position: relative;
  display: inline-block;
  vertical-align: middle;
  width: 90%;
  max-width: 500px;
  background: url("../images/container-bg.png") repeat left top;
  border: 2px solid #e59f2d;
  padding: 20px;
  .window-close{
    width: 25px;
    height: 25px;
    background-color: #000000;
    border-radius: 50%;
    border: 2px solid #ffffff;
    position: absolute;
    top: -12px;
    right: -12px;
    cursor: pointer;
    &:after,
    &:before{
      content: '';
      display: block;
      width: 15px;
      height: 2px;
      background-color: #ffffff;
      position: absolute;
      top: 9px;
      right: 3px;
      transform: rotate(45deg);
    }
    &:after{
      transform: rotate(-45deg);
    }
  }
  .window-tit{
    text-align: center;
    margin-bottom: 15px;
    span{
      font-size: 30px;
      display: block;
      text-align: center;
      margin-bottom: 10px;
      color: #ffffff;
      font-weight: 300;
    }
    img{

    }
  }
  p{
    text-align: center;
    margin-top: 15px;
  }
}
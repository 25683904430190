.breadcrumbs{

  a{

  }
  span{

  }
  a, span{
    &::after{

    }
    &:last-child{
      &::after{
        display: none;
      }
    }
  }
}
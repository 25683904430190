@mixin placeholder($color){
  &::-webkit-input-placeholder {
    color: $color;
    @content;
  }
  &::-moz-placeholder {
    color: $color;
    @content;
  }
  &:-moz-placeholder {
    color: $color;
    @content;
  }
  &:-ms-input-placeholder {
    color: $color;
    @content;
  }

}
@mixin placeholderHover($color){
  &:hover::-webkit-input-placeholder,
  &:focus::-webkit-input-placeholder{
    color: $color;
    @content;
  }
  &:hover::-moz-placeholder,
  &:focus::-moz-placeholder{
    color: $color;
    @content;
  }
  &:hover:-moz-placeholder,
  &:focus:-moz-placeholder{
    color: $color;
    @content;
  }
  &:hover:-ms-input-placeholder,
  &:focus:-ms-input-placeholder{
    color: $color;
    @content;
  }
}
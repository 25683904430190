.clear {
 @include clearfix;
}

.pull-right {
  float: right;
}
.pull-left {
  float: left;
}

.no-margin{
  margin: 0;
}

.rounded{
  border-radius: 50%;
}

.noborder {
  border: none;
}

.justify{
  width: 100%;
}

.text-center{
  text-align: center;
}

.font-inherit{
  @include fontInherit;
}
.font-light{
  font-weight: $font-light;
}
.font-regular{
  font-weight: $font-regular;
}
.font-semibold{
  font-weight: $font-semibold;
}
.font-bold{
  font-weight: $font-bold;
}

.hide-xs{
  @include mediaMax($screen-xs -1){
    display: none!important;
  }
}
.show-xs{
  @include mediaMin($screen-xs){
    display: none!important;
  }
}
.hide-sm{
  @include mediaMax($screen-sm - 1){
    display: none!important;
  }
}
.show-sm{
  @include mediaMin($screen-sm){
    display: none!important;
  }
}
.hide-md{
  @include mediaMax($screen-md){
    display: none!important;
  }
}
.show-md{
  @include mediaMin($screen-md){
    display: none!important;
  }
}
.hide-lg{
  @include mediaMax($screen-lg){
    display: none!important;
  }
}
.show-lg{
  @include mediaMin($screen-lg){
    display: none!important;
  }
}